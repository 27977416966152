@charset "utf-8";
/* CSS Document */

.auto-center{
    position: absolute;
    top: 50%;
    left: 50%;
    width: 300px;
    height: 240px;
    margin: 0 auto;
    margin-top: -120px;
    margin-left: -150px;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}
.auto-center img {/*width: 30px; height: 30px;*/}

/* load more */
.auto-load-more {float: left; width: 100%;}
.auto-loading span {display: block; width: 100%; text-align: center;}
.auto-loading span img {width: 10px; height: 10px;}
.auto-more a span {display: block; width: 100%; color: #6098f5; text-align: center;}
.auto-more a:hover span {color: blue}
.auto-more a span img {width: 12px; height: 10px; margin-left: 1px}
.auto-empty span {display: block; width: 100%; text-align: center;}
.auto-square .load-more {
  display: block; margin: 0 auto; width: 150px; height: 40px; line-height:40px; 
  border-radius: 10px; background: #6098f5; color: #ffffff; text-align: center; 
  font-size: 13px; font-family:"HiraginoSansGB-W3";font-weight: 100; cursor:pointer; }
.auto-square .auto-more {margin-left: 15px;}
.auto-square .auto-more a span {display: block; width: 100%; color: #fff; text-align: center;}
.auto-square .auto-more a:hover span {color: #fff}

/* square */
.auto-square-home .home_list{background:#fff; margin-bottom:10px; padding:10px 20px;}
.auto-square-home .home_list ul li{ border-bottom:1px solid #e9e9e9; padding-bottom:20px;}
.auto-square-home .home_list ul li:nth-last-of-type(1){ border-bottom:none}
.auto-square-home .home_list dl{ border-bottom:1px solid #EFEFEF; padding:10px 0 7px; }
.auto-square-home .home_list dl:nth-last-of-type(1){ border-bottom:none}
.auto-square-home .home_list dl dt{ height:25px; width:25px; border-radius:100%; float:left; margin-right:15px;}
.auto-square-home .home_list dl dt a{ display:block;height:25px; width:25px; border-radius:100%; }
.auto-square-home .home_list dl dt a img{display:block; height:25px; width:25px; border-radius:100%; }
.auto-square-home .home_list dl dd{ float:left; width:calc(100% - 40px)}
.auto-square-home .home_list .home-text {font-size: 14px; color: #666; line-height: 23px; margin-top: 10px;}
.auto-square-home .home_list .home-text a {color: #6098f5;}
.auto-square-home .home_list .home-text a:hover {color:#80ADF7;}
.auto-square-home .home_list .home-text .home-text-title {margin-bottom: 5px;}
.auto-square-home .home_list .home-text .home-text-title a {color: #000;}
.auto-square-home .home_list .home-text .home-text-title a:hover {color:#80ADF7;}
.auto-square-home .home_list .home-text .home-text-title img {width: 13px; height: 13px; margin-right: 5px;}
.auto-square-home .home_list .foot_bq{ margin-top:5px;margin-left: 65px;}
.auto-square-home .home_list .foot_bq label{font-size: 12px;}
.auto-square-home .anonymous_list dl dt{ height:50px; width:50px; border-radius:100%; float:left; margin-right:15px;}
.auto-square-home .anonymous_list dl dt a{ display:block;height:50px; width:50px; border-radius:100%; }
.auto-square-home .anonymous_list dl dt a img{display:block; height:50px; width:50px; border-radius:100%; }
.auto-square-home .anonymous_list dl dd{ float:left; width:calc(100% - 65px)}
.auto-square-home .pre_t{float:left}
.auto-square-home .pre_t h5 a{ display:block; line-height:20px; font-size:14px; color:#000}
.auto-square-home .pre_t span{ display:block; font-size:12px; color:#999; line-height:20px;margin-top:2px}
.auto-square-home .r_caozuo{ float:right; width:250px; position:relative}
.auto-square-home .r_caozuo i{ display:inline-block; width:14px; height:8px; line-height:8px; float:right; cursor: pointer;}
.auto-square-home .r_caozuo label{border:1px solid #ECECEC; box-shadow:0 0 4px rgba(0,0,0,.08); border-radius:2px; background:#fff; display:block; position:absolute; z-index:22; top:20px; right:0; width:110px;}
.auto-square-home .r_caozuo label a{ font-size:14px; color:#666; line-height:35px; display:block; text-align:center;}
.auto-square-home .r_caozuo label a:hover{ background:#ECECEC; color:#6095F2;box-shadow:0 0 4px rgba(0,0,0,.08);}
.auto-square-home .r_caozuo ul{ float:right;}
.auto-square-home .r_caozuo ul li{ float:right; padding-bottom:0; border:0; line-height:28px;}
.auto-square-home .r_caozuo ul li a{ font-size:12px; color:#999;}
.auto-square-home .r_caozuo ul li a:hover{ color:#6095F2}
.auto-square-home .r_caozuo ul em{ display:inline-block; width:1px; height:12px; background:#999; float:right; margin:10px 10px 0;}
.auto-square-home .r_caozuo .zkzd{ float:right; margin-right:0; margin-left:10px} 
.auto-square-home .r_caozuo .zkzd span{ margin-right:0; margin-left:6px;}

.auto-square-articles .n_article{background:#fff; border:1px solid #E6EAF1; border-radius: 4px; padding:20px 20px 0px 20px;}
.auto-square-articles .n_article dl{ border-bottom:1px solid #F0F0F0; padding-bottom:15px; margin-bottom:15px;}
.auto-square-articles .n_article dl:nth-last-of-type(1){ border-bottom:none}
.auto-square-articles .n_article dl dt{ height:138px; width:220px; float:left; margin-right:20px;}
.auto-square-articles .n_article dl dt a{ display:block; height:138px; width:220px;}
.auto-square-articles .n_article dl dt a img{ display:block; height:138px; width:220px;}
.auto-square-articles .n_article dl dd{ float:left; width:calc(100% - 240px);}
.auto-square-articles .n_article dl dd h2{ line-height:24px; max-height:50px; overflow:hidden;}
.auto-square-articles .n_article dl dd h2 a{ font-size:18px; color:#333;font-family:'微软雅黑';font-weight: bold}
.auto-square-articles .a_label{ margin:7px 0 6px; height:50px; overflow:hidden;}
.auto-square-articles .a_label a{ color:#afcaf8; font-size:12px; background:#edf3fd; border-radius:2px; height:20px; line-height:20px; padding:0 10px; display:inline-block; margin:0 10px 5px 0;}
.auto-square-articles .a_foot a{ display:inline-block; float:left}
.auto-square-articles .a_foot a.peopic{ height:24px; line-height:24px; display:inline-block; border-radius:100%; margin-right:10px;}
.auto-square-articles .a_foot a.peopic img{ height:24px; line-height:24px; display:inline-block; border-radius:100%; vertical-align: top}
.auto-square-articles .a_foot a.peoname{ width:78px; height:24px; line-height:24px; font-size:12px; color:#999; overflow:hidden;text-overflow:ellipsis;white-space: nowrap;}
.auto-square-articles .a_foot span{ line-height:24px; font-size:12px; color:#999;}
.auto-square-articles .a_foot .foot_bq{ display:inline-block; float:right;}
.auto-square-articles .a_foot .foot_bq label{ margin-right:0}
.auto-square-articles label.browse{background: url("../img/browse1.png") left center no-repeat; background-size: 12px;}

.auto-square-topic .topic_square ul li{float:left; margin:0 12px 12px 0;padding:10px; border:1px solid #F0F0F0; border-radius:3px; height:72px; width:178px; background:#fff; overflow:hidden}
.auto-square-topic .topic_square ul li:nth-of-type(3n){ margin-right:0}
.auto-square-topic .topic_square ul li dl{ }
.auto-square-topic .topic_square ul li dl dt{ float:left; width:40px; height:40px; margin-right:10px;}
.auto-square-topic .topic_square ul li dl dt img{ display:block; width:40px; height:40px; margin:0 auto; border-radius:100%;}
.auto-square-topic .topic_square ul li dl dd{ float:left; width:128px;}
.auto-square-topic .topic_square ul li dl dd a{ display:block; font-size:16px; margin-top:4px; color:#333;font-family:"HiraginoSansGB-W6";font-weight:bold; line-height:25px;overflow:hidden;text-overflow:ellipsis;white-space: nowrap; }
.auto-square-topic .topic_square ul li dl dd a:hover{ color:#6098F5}
.auto-square-topic .topic_square ul li .foot_bq{ margin-top:9px;}
.auto-square-topic .topic_square ul li .foot_bq label{ font-size:11px; color:#808080; line-height:22px; padding-left:17px; width:41px; margin-right:4px; display:inline-block; float:left } 
.auto-square-topic .topic_square ul li .foot_bq label.discuss{background:url(../img/discuss.png) left center no-repeat; background-size:12px }
.auto-square-topic .topic_square ul li .foot_bq label.follow2{background:url(../img/follow2.png) left center no-repeat; background-size:12px }
.auto-square-topic .topic_square ul li .foot_bq a{ display:inline-block; height:20px; line-height:20px; width:29px; padding-bottom:0; margin-top:0; border:1px solid #f0f0f0; font-size:12px;color:#808080; background:url(../img/add.png) 4px center no-repeat; background-size:10px; padding-left:19px;font-family: HiraginoSansGB-W3;  }
.auto-square-topic .topic_square ul li .foot_bq a.gray{ background:none; padding:0; text-align:center; width:48px;}

/* search */
.auto-color-red {color: red;}
.auto-flush-loading {display: block; width: 100%; text-align: center; padding: 60px 0;}
.auto-flush-loading img {width: 20px; height: 20px;}

/* launch ques and article */
.auto-wtwz {float: left; margin-top: 20px; margin-left: 50px; line-height: 34px;}
.auto-wtwz a {float: left; display: block; margin-left:20px; color: #6098f5; line-height: 34px;}
.auto-wtwz a:hover {color: blue}
.auto-wtwz a img {float: left; display: block; width:16px; height:16px; padding: 9px 4px 9px 0px;}

/* register and login */
.auto-zcdl{float:right; margin-left:10px; margin-top:21px;}
.auto-zcdl a{ float:right; font-size:14px; height:34px; line-height:34px; border-radius:4px; width:70px; text-align:center; margin-left:3px;font-family:"HiraginoSansGB-W3"}
.auto-zcdl a.dl{background:rgba(96,152,245,.15);color:#6098f5;}
.auto-zcdl a.dl:hover{ background:#6098f5; color:#fff;}
.auto-zcdl a.zc{ color:#6098f5; }
.auto-zcdl a.zc:hover{background:rgba(96,152,245,.15); }

/* publish question and article */
.Launch_box .aw-publish-title .ser_list{
    border: 1px solid #F0F0F0;
    position: absolute;
    z-index: 22;
    top: 54px;
    left: 0;
    background: #fff;
    max-height: 200px;
    overflow: auto;
    width: 580px;
    display: none;
}
#answer_content_message a {color: blue;}
.auto-article-cover {display: block; width: 140px; height: 92px;}

/* question page */
.collapse {display:none}
.problem_d .p1 img {max-width: 100%; max-height: 584px}
.problem_d .p1 span {float: right; font-size:14px;}
.aw-edit-topic-box .add {cursor:pointer;}
.aw-edit-topic-box .close-edit {cursor:pointer;}
.Middle_area .details_box .load_more_li {padding-top: 10px;}
.Middle_area .details_box .load_more {padding: 0; font-size: 14px;}
.Middle_area .details_box .load_more .auto-more img {width: 9px; height: 7px;}

/* article page */
.Middle_area .wz_details .auto-wz-operate {float: right;}
.Middle_area .wz_details .auto-wz-operate label{
    color:#999; font-size:14px; margin-right:10px;font-family:"HiraginoSansGB-W3"; line-height:30px; cursor:pointer; padding-left:17px;
}
.Middle_area .wz_details .auto-wz-operate label a{color:#999;}
.Middle_area .wz_details .auto-wz-operate label.edit{background:url("../img/bj.png") left center no-repeat; background-size:12px}
.Middle_area .wz_details .auto-wz-operate label.del{background:url("../img/del.png") left center no-repeat; background-size:12px}
.Middle_area .wz_details .auto-wz-operate label.lock{background:url("../img/lock.png") left center no-repeat; background-size:12px}

.Middle_area .wz_details div.topic_label {clear: both;}
.Middle_area .wz_details .topic_label a {margin: 0px 6px 0px 0px;}
.Middle_area .wz_details span.aw-edit-topic_article {
    color:#999;border:1px solid #fff;border-radius:5px;padding-left:17px;margin:0px;
    cursor:pointer;background:url("../img/bj.png") left center no-repeat; background-size:14px;
}
.Middle_area .wz_details .Paran_box {padding-top:10px;}
.Middle_area .comment_box .load_more_li {padding-top: 10px; padding-bottom: 10px;}
.Middle_area .comment_box .load_more {padding: 0; font-size: 14px;}
.Middle_area .comment_box .load_more .auto-more img {width: 9px; height: 7px;}
.Middle_area .wz_details .hdbox label.del{
    color:#999; font-size:14px; margin-right:10px;font-family:"HiraginoSansGB-W3"; line-height:30px; cursor:pointer; padding-left:17px;
}
.Middle_area .wz_details .hdbox label.del a{color:#999;}
.Middle_area .comment_box .hdbox label.del{float:right; background:url("../img/del.png") left center no-repeat; background-size:12px}
.Middle_area .wz_details .Put_questions div.auto-captcha {display: inline-block;}
.Middle_area .wz_details .Put_questions input {height: 35px;border: 1px solid #ddd;margin-bottom: 45px;margin-left: 10px;font-size: 14px;}

/* inbox */
.auto-inbox .auto-inbox-img {float:left; width:50px; height:50px; margin-top:5px;}
.auto-inbox .auto-inbox-img img { width:50px; height:50px; line-height:50px; border-radius:4px;}
.auto-inbox .auto-inbox-info {float:left; width: 90%; height:50px; margin-left: 7px; margin-top: 0px; padding: 0px;}
.auto-inbox .auto-inbox-info label {float:right;}
.auto-inbox .auto-inbox-info p a {color:#6098f5}
.auto-inbox .auto-inbox-info p a:hover {color:#80ADF7}
.auto-inbox .auto-inbox-info span em{
    display:inline; min-width:10px; height:16px; line-height:16px; padding:0 3px; 
    color:#fff; background:#FC6C38; text-align:center; border-radius:16px; font-size:10px; position:relative; top:-5px; right:5px;
}
.auto-inbox label.del{background:url("../img/del.png") left center no-repeat; background-size:12px; padding-left:20px;color:#999; font-size:14px;}

.auto-read-inbox {
    float: left; width: 500px; max-height: 570px; border-radius: 16px; padding-top:10px;
    font-size: 14px; position:absolute; z-index: 999; background:#EBEBEB;
}
.auto-read-inbox .message-list {position: relative; max-height: 455px; padding: 0px 5px 15px 5px; overflow-y:auto;}
.auto-read-inbox .message-list::-webkit-scrollbar {display: none;}
.auto-read-inbox .message-item {width: 100%; display: flex; margin-top: 8px;}
.auto-read-inbox .message-item .avatar{width: 40px; height: 40px; margin: 0 10px;}
.auto-read-inbox .message-item-right {flex-direction: row-reverse;}
.auto-read-inbox .message-bubble {border: 1px solid #ddd; padding: 7px 10px; border-radius: 3px; max-width: 257px; position: relative;}
.auto-read-inbox .message-item-left .message-bubble {background-color: #fff;}
.auto-read-inbox .message-item-left .message-bubble::before {
    content: '';
    border-top: 1px solid #ddd;
    border-left: 1px solid #ddd;
    width: 10px;
    height: 10px;
    background: #fff;
    position: absolute;
    top: 14px;
    left: -6px;
    transform: rotate(-45deg);
    border-radius: 0 0 20px 0;
}
.auto-read-inbox .message-item-right .message-bubble {background-color: #9fe759; border-color: #87cd51;}
.auto-read-inbox .message-item-right .message-bubble::before {
    content: '';
    border-top: 1px solid #87cd51;
    border-left: 1px solid #87cd51;
    width: 10px;
    height: 10px;
    background-color: #9fe759;
    position: absolute;
    top: 14px;
    right: -6px;
    transform: rotate(135deg);
    border-radius: 0 0 20px 0;
}
.auto-read-inbox .message-footer {
    position: relative;
    bottom: 0;
    width: 100%;
    height: 115px;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    background-color: #f5f5f7;
    justify-content: space-between;
    align-items: center;
}
.auto-read-inbox .message-footer .text-input{
    height: 70px;
    width: 78%;
    margin-top: 15px;
    margin-left: 10px;
    padding: 5px;
    border: 1px solid #ddd;
    border-radius: 10px;
    background: #f5f5f5;
}
.auto-read-inbox .message-footer .text-send{
    float: right;
    display: inline-block;
    width: 70px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-size: 16px;
    cursor: pointer;
    margin-top: 30px;
    margin-right: 10px;
    margin-bottom: 20px;
    border-radius: 10px;
    color: #fff;
    background-color: #008b00;
}
.auto-read-inbox .message-footer div.text-send:hover {background-color: darkgreen}

/* comment box */
.aw-comment-box{position:relative; margin:0px auto;border:1px solid #e6e6e6;background-color: #fff;}
.aw-comment-box .aw-comment-list{padding:15px 15px 0; font-size:14px; border-bottom: 1px solid #f2f2f2;}
.aw-comment-box .aw-comment-list .aw-padding10{padding-top: 0px;padding-bottom: 10px;}
.aw-comment-box .aw-comment-list a{color: #155faa;}
.aw-comment-list li{position: relative;padding:15px 0 15px 42px;border-top:1px solid #f5f5f5;line-height: 22px;}
.aw-comment-list li:first-child{padding-top: 0px;}
.aw-comment-list li > .aw-user-name{position: absolute;left: 0;}
.aw-comment-list li > .aw-user-name img{width: 32px;height: 32px;}
.aw-comment-list li p{margin: 0 0 0 38px;word-break:break-all;}
.aw-comment-list li span.pull-right {float: right}

/* account page */
.aw-register-box ul li {position: relative; line-height: 30px; margin-bottom: 20px;}
.aw-register-box li span {position: absolute;/*top: 0;*/right: -155px;width: 130px;padding: 5px;font-size: 12px;border-radius: 5px;overflow: hidden;text-align: left;}
.aw-reg-tips {line-height: 15px;background: #eef7fd;border: 1px solid #cfdde6;color: #999;}
.aw-reg-err {line-height: 28px;background: #ffeaea;border: 1px solid #e5c3c4;color: #C00;}

.auto-login-regist div{ 
    display: inline-block; font-size:16px; width:168px; border:1px solid #6098f5;border-radius:4px;
    font-family:"HiraginoSansGB-W3"; height:48px; line-height:48px; text-align:center; cursor:pointer}
.auto-login-regist div.zc{background:#fff; color:#6098f5;}

/* setting */
.btn-success {color: #fff; background-color: #5cb85c;border-color: #4cae4c;}
.btn-success, .btn-gray.active {border: none !important;background-color: #5bbf5a;color: #fff;}
.btn-mini {min-width: 64px;min-width: 44px\0;height: 24px;padding: 0 10px;line-height: 24px;font-weight: normal;font-size: 12px;}
.btn {
    display: inline-block;padding: 0px 12px;margin-bottom: 0;font-size: 14px;font-weight: 400;line-height: 1.42857143;text-align: center;white-space: nowrap;
    vertical-align: middle;cursor: pointer;-webkit-user-select: none;-moz-user-select: none;-ms-user-select: none;user-select: none;background-image: none;
    border: 1px solid transparent;border-radius: 4px;
}
#auto-basic-info .aw-user-setting .mod-base .side-bar form {position: relative;opacity: 0;filter: alpha(opacity=0);width: 0;height: 0;}
#auto-basic-info input[type=submit] {-webkit-appearance: button;cursor: pointer;}
.btn-success, .btn-gray.active {border: none !important;background-color: #e7f9f7;color: #6098f5;}
.btn-mini {min-width: 64px;min-width: 44px\0;height: 34px;padding: 0 10px;line-height: 34px;font-weight: normal;font-size: 14px;color: #6098f5;}

#auto-privacy-remind label{position: relative;}
#auto-privacy-remind input[type="radio"],input[type="checkbox"] {background-clip: content-box;padding: .2em;left: 0px;top: 10px;position: absolute;clip: rect(0, 0, 0, 0);}

#auto-change-mobile{border:1px solid #F0F0F0; background:#fff;padding:5px 20px 0; min-height:700px;border-radius:4px;}
#auto-change-mobile #wizard {
    height:600px;margin:20px auto;width:570px;overflow:hidden;position:relative;-moz-border-radius:5px;-webkit-border-radius:5px;}
#auto-change-mobile #wizard .items{width:20000px; clear:both; position:absolute;}
#auto-change-mobile #wizard .left{float:left;}
#auto-change-mobile #wizard .right{float:right;}
#auto-change-mobile #wizard #status{height:42px;margin-top:42px;background:#82b9ef;padding-left:25px !important;}
#auto-change-mobile #wizard #status li{float:left;color:#fff;padding:10px 30px;}
#auto-change-mobile #wizard #status li.active{background-color:#499ef3;font-weight:normal;}
#auto-change-mobile #wizard .input{width:240px; height:18px; margin:10px auto; line-height:20px; border:1px solid #d3d3d3; padding:2px}
#auto-change-mobile #wizard .page{padding:20px 30px;width:500px;float:left;}
#auto-change-mobile #wizard .page h3{height:42px; font-size:16px; border-bottom:1px dotted #ccc; margin-bottom:20px; padding-bottom:5px}
#auto-change-mobile #wizard .page h3 em{font-size:12px; font-weight:500; font-style:normal}
#auto-change-mobile #wizard .page p{line-height:24px;}
#auto-change-mobile #wizard .page p label{font-size:14px; display:block;}
#auto-change-mobile #wizard .btn_nav{height:36px; line-height:36px; margin:20px auto;}
#auto-change-mobile #wizard .prev,.next{width:100px; height:32px; line-height:32px; border:1px solid #d3d3d3; cursor:pointer}

#auto-change-mobile #wizard .page{
    border:1px solid #F0F0F0; background:#fff;padding:40px 0; border-radius:4px; width:565px; margin: 0px 20px 0px 0px; position:relative; top:40px;}
#auto-change-mobile #wizard .page h4{ 
    font-size:20px; text-align:center; color:#333; line-height:30px; margin:40px 0 32px}
#auto-change-mobile #wizard .page em.close{
    display:block; width:16px; height:16px; line-height:16px; text-align:center; position:absolute; right:20px; top:20px; cursor:pointer}
#auto-change-mobile #wizard .page .binding ul li{ 
    border:1px solid #E6EAF1; background:#F1F2F4; height:48px; width:368px; margin:0 auto 20px; padding-left:10px; border-radius:4px;}
#auto-change-mobile #wizard .page .binding ul p{ 
    border:1px solid #E6EAF1; background:#F1F2F4; height:48px; width:368px; margin:0 auto 20px; padding-left:10px; border-radius:4px;}
#auto-change-mobile #wizard .page .binding ul img{ 
    margin:0 80px 80px 80px;width:320px;height:320px;}
#auto-change-mobile #wizard .page .binding ul li input{ 
    height:48px; border:none; vertical-align:top; border-radius:4px; width:285px; font-size:16px; padding:0 10px }
#auto-change-mobile #wizard .page .binding ul li input::-webkit-input-placeholder{color:#ACB0B7;}
#auto-change-mobile #wizard .page .binding ul li input::-moz-placeholder{color:#ACB0B7;}
#auto-change-mobile #wizard .page .binding ul li input:-moz-placeholder{color:#ACB0B7;}
#auto-change-mobile #wizard .page .binding ul li input:-ms-input-placeholder{color:#ACB0B7;}
#auto-change-mobile #wizard .page .binding ul li span{ 
    height:48px; line-height:48px; font-size:16px; color:#666}
#auto-change-mobile #wizard .page .binding ul li span em{
    display:inline-block; width:0; height:0; border-width:7px 6px 0; margin:0 10px 0 10px; border-style:solid; 
    border-color:#BDBDBD  transparent transparent;/*灰 透明 透明 */}
#auto-change-mobile #wizard .page .binding ul li.wsmall{
    border:none; padding:0; background:none; width:380px;}
#auto-change-mobile #wizard .page .binding ul li.wsmall input{ 
    border:1px solid #E6EAF1; background:#fff; width:218px}
#auto-change-mobile #wizard .page .binding ul li button{ 
    background: #6095F2; cursor:pointer;width:120px; height:50px; line-height:50px; border:none; text-align:center; 
    color:#fff; font-size:16px; border-radius:4px; margin-left:20px;}
#auto-change-mobile #wizard .page .f_but input{ 
    display:block; font-size:16px; color:#fff; height:50px; line-height:50px; width:180px; margin:40px auto; 
    text-align:center;border-radius:4px; background:#6095F2}
#auto-change-mobile #wizard .page .btn_nav .left{float:left; margin-left: 50px;}
#auto-change-mobile #wizard .page .btn_nav .right{float:right; margin-right: 50px;}

/* 404 page */
.style404 {display: -webkit-box;-webkit-box-pack:center;-webkit-box-align:center;-webkit-box-orient: vertical;text-align: center}