@charset "utf-8";
/* CSS Document */

/* 全局样式格式化 */
*{margin:0;padding:0;}
body, html{ width:100%; }
body{background:#f6f6f6;font:12px/22px; font-family:"HiraginoSansGB-W6","Microsoft YaHei", "微软雅黑", "STHeiti", "WenQuanYi Micro Hei", SimSun, sans-serif; }
body,ul,ol,li,p,h1,h2,h3,h4,h5,h6,form,fieldset,table,td,img,div,tr{margin:0;padding:0; font-weight:normal}
input,select{font-size:12px;vertical-align:middle; border:none;}
ul,li{list-style-type:none;}
img{border:0 none;}
a:link, a:visited{ color:#000; text-decoration: none;}
a:hover{color:#6098f5}
h1{font-size:24px;}
h2{font-size:20px;}
h3{font-size:18px;}
h4{font-size:16px;}
h5{font-size:14px;}
h6{font-size:12px;}
.clear{ display:block; clear:both; height:0px; width:100%; font-size:0; line-height:0px; visibility:hidden; overflow:hidden;}
i,em{ font-style:normal}
p,a,h1,h2,h3,h4,h5,h6,span,label{word-break:break-all;word-wrap:break-word;}

/* 基础布局 */
.fl{float:left}
.fr{float:right}
.fnone{float:none;}
.wlang{width:100%;}
.w1440{width:1440px;}
.w1200{width:1200px;}
.w1100{width:1100px;}
.w1000{width:1000px;}
.w900{width:900px;}
.w800{width:800px;}
.w80{ width:80px;}
.w50{ width:50px;}
.w100{ width:100px;}
.w140{ width:140px;}
.h30{ height:30px; line-height:30px;}
.auto{margin:0 auto}
.mt5{margin-top:5px}
.mt10{margin-top:10px}
.mt15{margin-top:15px}
.mt20{margin-top:20px}
.mt25{margin-top:25px}
.mt30{margin-top:30px}
.mb5{margin-bottom:5px}
.mb10{margin-bottom:10px}
.mb15{margin-bottom:15px}
.mb20{margin-bottom:20px}
.ml10{ margin-left:10px;}
.mb30{margin-bottom:30px}
.block{ display:block;}
.inline{ display:inline-block;}
.ml20{ margin-left:20px;}
.ml30{ margin-left:30px;}
.hr20{ height:20px;}
.hr40{ height:40px; line-height:40px;}
.hr80{ height:80px;}
.pointer{ cursor:pointer;}



/* 分割线 */
.border{border:1px solid #eee;}
.line333{border-color:#333333}
.line666{border-color:#333333}
.line999{border-color:#333333}
.lineccc{border-color:#cccccc}
.lineeee{border-color:#eeeeee}
.line5{border-color:#e5e5e5;}
.line_b{ border-bottom:1px solid #e0e0e0;}
.border_c{ border:1px solid #ccc;}

/* 基础文字大小*/
.font12{font-size:12px;}
.font14{font-size:14px;}
.font16{font-size:16px;}
.font18{font-size:18px;}
.font20{font-size:20px;}
.font22{font-size:22px;}
.font24{font-size:24px;}
.font25{font-size:24px;}
.font40{font-size:40px;}

/* 基础补白 */
.p5{padding:5px;}
.p10{padding:10px;}
.p15{padding:15px;}
.p20{padding:20px;}
.pt10{padding-bottom:10px;}
.pt15{padding-bottom:15px;}
.pt20{padding-bottom:20px;}
.pt25{padding-bottom:25px;}
.pt30{padding-bottom:30px;}
.pb10{padding-bottom:10px;}
.pb15{padding-bottom:15px;}
.pb20{padding-bottom:20px;}
.pb25{padding-bottom:25px;}
.pb30{padding-bottom:30px;}
.pl5{padding-left:5px;}
.pl10{padding-left:10px;}
.pl15{padding-left:15px;}
.pl20{padding-left:20px;}
.pr5{padding-right:5px;}
.pr10{padding-right:10px;}
.pr15{padding-right:15px;}
.pr20{padding-right:20px;}
.pad55{ padding:55px;}
.pad40{ padding:40px;}
.pad10{ padding:10px;}
.pad20{ padding:20px;}




