@charset "utf-8";
/* CSS Document */
/*首页*/
.w1150{ width:1150px; margin:0 auto}
.top_nav{ height:68px; line-height:68px; border-bottom:1px solid #f0f0f0; background:#fff;}
.suspension{ position:fixed; top:0; left:0; width:100%; z-index:998;}
.index_logo{ width:60px; height:30px; line-height:30px; float:left; margin-top:20px; margin-right:25px; }
.index_logo a{ display:inline-block; width:60px; height:30px; line-height:30px;}
.index_logo a img{display:inline-block; width:60px; height:30px;}
.nav_box{  width:421px; float:left;}
.nav_box ul{list-style-type:none;}
.nav_box ul li{ width:auto; margin:0 15px; float:left;height:68px; line-height:68px;list-style-type:none;}
.nav_box ul li a{color:#7f7f7f; font-size:16px; font-family:"HiraginoSansGB-W3"  }
.nav_box ul li:hover a{ color:#333333;font-family:"HiraginoSansGB-W6";font-weight:bold;}
.nav_box ul li.slected a{color:#333333; font-family:"HiraginoSansGB-W6";font-weight:bold;}
.top_search{ width:250px; border-bottom:1px solid #333333; height:28px; line-height:28px; float:left; margin-top:22px; position:relative;}

.top_search input{ width:236px; font-size:16px; outline:none; color:#1A1A1A;float:left; font-family:"HiraginoSansGB-W3"; margin-top:2px;}
.top_search input::-webkit-input-placeholder{color:#ccc;}
.top_search input::-moz-placeholder{color:#ccc;}
.top_search input:-moz-placeholder{color:#ccc;}
.top_search input:-ms-input-placeholder{color:#ccc;}

.top_search button{background:url("../img/sou.png") left center no-repeat; background-size:14px; width:14px; height:14px; border:none; float:left; margin-top:4px; cursor:pointer}
.blue_focus{ border-bottom:1px solid #6098f5;}
.blue_focus button{background:url("../img/sou2.png") left center no-repeat;background-size:14px;}

.search_result{ position:absolute; z-index:2;width:360px; border:1px solid #f0f0f0; border-top:none;border-radius:4px; top:46px; left:0; background:#fff; display:none}
.search_result a{ display:block; font-size:14px; color:#1A1A1A; line-height:40px; height:40px; padding:0 20px; text-align:left;overflow: hidden;text-overflow:ellipsis;white-space: nowrap;font-family:"HiraginoSansGB-W3" ;}
.search_result a img{ display:inline-block; width:24px; height:24px; margin-right:8px; vertical-align:-8px;}
.search_result a i{ color:#6098f5;font-family:"HiraginoSansGB-W6";font-weight:bold; }
.search_result a em{ font-size:12px; color:#999;font-family:"HiraginoSansGB-W3"; margin-left:10px;}
.search_result a label{font-family:"HiraginoSansGB-W3"; color:#6098f5; float:left; margin-top:8px; border:1px solid #6098f5;border-radius:12px; font-size:12px; height:22px; line-height:22px; padding:0 9px;  display:inline-block;}
.search_result a em.fr{ float:right;  font-size:14px}
.search_result p{ text-align:center; color:#1A1A1A; font-size:14px;font-family:"HiraginoSansGB-W3"; line-height:40px; border-top:1px solid #f0f0f0; margin-top:8px;}
.search_result p a{ display:inline; padding:0;color:#6098f5}
.search_result a:hover{ background:#fafafa}
/*.l_more{ height:39px; line-height:39px; border-top:1px solid #f0f0f0; text-align:center;}
.l_more button{font-size:14px; color:#ccc;font-family:"HiraginoSansGB-W3" ; display:block; border:none; text-align:center; background:none; height:39px; line-height:39px; width:358px; cursor:pointer}
.l_more button:hover{ color:#6098f5}
*/

.zcdl{ width:250px; float:right; margin-left:10px; margin-top:18px;}
.zcdl a{ float:right; font-size:14px; height:34px; line-height:34px; border-radius:4px; width:60px; text-align:center; margin-left:10px;font-family:"HiraginoSansGB-W3"}
.zcdl a.dl{background:rgba(96,152,245,.15);color:#6098f5;}
.zcdl a.dl:hover{ background:#6098f5; color:#fff;}
.zcdl a.zc{ color:#6098f5; }
.zcdl a.zc:hover{background:rgba(96,152,245,.15); }
.slogan{ height:180px;background:#fff;}
.slogan dt{ float:left; width:1010px; margin-right:20px;}
.slogan dt h1{ font-size:26px; color:#fafafa; line-height:40px; margin-top:55px;font-family:"HiraginoSansGB-W6"; font-weight:bold;}
.slogan dt p{ font-size:18px; color:#fafafa; line-height:30px;font-family:"HiraginoSansGB-W3" }
.slogan dd{ float:right; width:120px}
.Launch_but{margin-top:60px;background:#6098f5; height:34px;line-height:34px; width:120px;border-radius:4px; transition:height .8s }
.Launch_but span{ display:block; color:#fff; font-size:12px; font-family:"HiraginoSansGB-W3" ; text-align:center; cursor:pointer }
.Launch_but a{ display:block; color:#fff; line-height:40px; font-size:12px; font-family:"HiraginoSansGB-W3" ; text-align:center; }
.Launch_but:hover{ height:114px; }
.Launch_but a img{ vertical-align:middle; margin-right:5px; width:16px; height:16px;}
.Launch_but a:hover{ background:#7facf6}


.Main_area{ padding:10px 0}
.left_nav{ width:206px; float:left;}
.left_hd{ position:fixed; z-index:40; top:100px; }
.left_nav dl{ margin-bottom:8px;}
.left_nav dl dd a{ display:block; height:34px; line-height:34px;border:1px solid #f6f6f6; font-size:14px; color:#666666; text-indent:70px; margin-bottom:5px;}
.left_nav dl dt a{ color:#333; font-size:16px; line-height:36px; padding-left:35px; display:block;font-family:"HiraginoSansGB-W3"; }
.left_nav dl dt{ position:relative; z-index:1}
.left_nav dl dt i.del{ position:absolute; z-index:22; top:5px; right:0; color:#6098f5; font-size:14px;font-family:"HiraginoSansGB-W3"; margin-top:4px; }

.left_nav dl.nav1 dt a{background:url("../img/dt1.png") 10px center no-repeat; background-size:16px;}
.left_nav dl.nav2 dt a{background:url("../img/wd1.png") 10px center no-repeat;background-size:16px; }
.left_nav dl.nav3 dt a{background:url("../img/zl1.png") 10px center no-repeat;background-size:16px; }
.left_nav dl.nav4 dt a{background:url("../img/ht1.png") 10px center no-repeat; background-size:16px; }
.left_nav dl.nav5 dt a{background:url("../img/tz1.png") 10px center no-repeat;background-size:16px; }
.left_nav dl.nav6 dt a{background:url("../img/sx1.png") 10px center no-repeat;background-size:16px; }
.left_nav dl.nav7 dt a{background:url("../img/help1.png") 10px center no-repeat;background-size:16px; }
.left_nav dl.nav8 dt a{background:url("../img/bj1.png") 10px center no-repeat;background-size:16px; }
.left_nav dl.nav9 dt a{background:url("../img/ht1.png") 10px center no-repeat;background-size:16px; }
.left_nav dl.nav10 dt a{background:url("../img/bj1.png") 10px center no-repeat;background-size:16px; }
.left_nav dl.nav11 dt a{background:url("../img/sc1.png") 10px center no-repeat; background-size:16px;}
.left_nav dl.nav12 dt a{background:url("../img/gz1.png") 10px center no-repeat; background-size:16px;}
.left_nav dl.nav13 dt a{background:url("../img/yq1.png") 10px center no-repeat;background-size:16px; }
.left_nav dl.nav14 dt a{background:url("../img/user1.png") 10px center no-repeat;background-size:16px; }
.left_nav dl.nav15 dt a{background:url("../img/sz1.png") 10px center no-repeat;background-size:16px; }
.left_nav dl.nav16 dt a{background:url("../img/sy1.png") 10px center no-repeat;background-size:16px; }
.left_nav dl.nav17 dt a{background:url("../img/ser1.png") 10px center no-repeat; background-size:16px;}
.left_nav dl.nav18 dt a{background:url("../img/bi1.png") 10px center no-repeat; background-size:16px;}
.left_nav dl.nav19 dt a{background:url("../img/ordinary1.png") 10px center no-repeat; background-size:16px;}
.left_nav dl.nav20 dt a{background:url("../img/article1.png") 10px center no-repeat; background-size:16px;}
.left_nav dl.nav21 dt a{background:url("../img/user-1.png") 10px center no-repeat; background-size:16px;}

.left_nav dl dt a:hover{font-family:"HiraginoSansGB-W6";font-weight:bold;}

.left_nav dl.nav1 dt a:hover{background:url("../img/dt2.png") 10px center no-repeat; background-size:16px;}
.left_nav dl.nav2 dt a:hover{background:url("../img/wd2.png") 10px center no-repeat;background-size:16px; }
.left_nav dl.nav3 dt a:hover{background:url("../img/zl2.png") 10px center no-repeat;background-size:16px;  }
.left_nav dl.nav4 dt a:hover{background:url("../img/ht2.png") 10px center no-repeat;background-size:16px; }
.left_nav dl.nav5 dt a:hover{background:url("../img/tz2.png") 10px center no-repeat; background-size:16px; }
.left_nav dl.nav6 dt a:hover{background:url("../img/sx2.png") 10px center no-repeat; background-size:16px;  }
.left_nav dl.nav7 dt a:hover{background:url("../img/help2.png") 10px center no-repeat; background-size:16px;  }
.left_nav dl.nav8 dt a:hover{background:url("../img/bj2.png") 10px center no-repeat; background-size:16px;  }
.left_nav dl.nav9 dt a:hover{background:url("../img/ht2.png") 10px center no-repeat; background-size:16px;  }
.left_nav dl.nav10 dt a:hover{background:url("../img/bj2.png") 10px center no-repeat; background-size:16px;  }
.left_nav dl.nav11 dt a:hover{background:url("../img/sc2.png") 10px center no-repeat; background-size:16px;  }
.left_nav dl.nav12 dt a:hover{background:url("../img/gz2.png") 10px center no-repeat; background-size:16px;  }
.left_nav dl.nav13 dt a:hover{background:url("../img/yq2.png") 10px center no-repeat; background-size:16px;  }
.left_nav dl.nav14 dt a:hover{background:url("../img/user2.png") 10px center no-repeat;  background-size:16px; }
.left_nav dl.nav15 dt a:hover{background:url("../img/sz2.png") 10px center no-repeat;background-size:16px;   }
.left_nav dl.nav16 dt a:hover{background:url("../img/sy2.png") 10px center no-repeat;background-size:16px;   }
.left_nav dl.nav17 dt a:hover{background:url("../img/ser2.png") 10px center no-repeat; background-size:16px;  }
.left_nav dl.nav18 dt a:hover{background:url("../img/bi2.png") 10px center no-repeat; background-size:16px;  }
.left_nav dl.nav19 dt a:hover{background:url("../img/ordinary2.png") 10px center no-repeat; background-size:16px; }
.left_nav dl.nav20 dt a:hover{background:url("../img/article2.png") 10px center no-repeat; background-size:16px; }
.left_nav dl.nav21 dt a:hover{background:url("../img/user-2.png") 10px center no-repeat; background-size:16px; }

.left_nav dl.cur dt a{font-family:"HiraginoSansGB-W6"; color:#1a1a1a;font-weight:bold;}

.left_nav dl.nav1.cur dt a{background:url("../img/dt2.png") 10px center no-repeat; background-size:16px;}
.left_nav dl.nav2.cur dt a{background:url("../img/wd2.png") 10px center no-repeat;background-size:16px;  }
.left_nav dl.nav3.cur dt a{background:url("../img/zl2.png") 10px center no-repeat; background-size:16px;  }
.left_nav dl.nav4.cur dt a{background:url("../img/ht2.png") 10px center no-repeat; background-size:16px;}
.left_nav dl.nav5.cur dt a{background:url("../img/tz2.png") 10px center no-repeat; background-size:16px;}
.left_nav dl.nav6.cur dt a{background:url("../img/sx2.png") 10px center no-repeat; background-size:16px; }
.left_nav dl.nav7.cur dt a{background:url("../img/help2.png") 10px center no-repeat; background-size:16px;  }
.left_nav dl.nav8.cur dt a{background:url("../img/bj2.png") 10px center no-repeat; background-size:16px; }
.left_nav dl.nav9.cur dt a{background:url("../img/ht2.png") 10px center no-repeat; background-size:16px; }
.left_nav dl.nav10.cur dt a{background:url("../img/bj2.png") 10px center no-repeat;background-size:16px;  }
.left_nav dl.nav11.cur dt a{background:url("../img/sc2.png") 10px center no-repeat; background-size:16px; }
.left_nav dl.nav12.cur dt a{background:url("../img/gz2.png") 10px center no-repeat; background-size:16px; }
.left_nav dl.nav13.cur dt a{background:url("../img/yq2.png") 10px center no-repeat; background-size:16px; }
.left_nav dl.nav14.cur dt a{background:url("../img/user2.png") 10px center no-repeat; background-size:16px; }
.left_nav dl.nav15.cur dt a{background:url("../img/sz2.png") 10px center no-repeat;background-size:16px;  }
.left_nav dl.nav16.cur dt a{background:url("../img/sy2.png") 10px center no-repeat; background-size:16px; }
.left_nav dl.nav17.cur dt a{background:url("../img/ser2.png") 10px center no-repeat; background-size:16px; }
.left_nav dl.nav18.cur dt a{background:url("../img/bi2.png") 10px center no-repeat; background-size:16px; }
.left_nav dl.nav19.cur dt a{background:url("../img/ordinary2.png") 10px center no-repeat; background-size:16px;}
.left_nav dl.nav20.cur dt a{background:url("../img/article2.png") 10px center no-repeat; background-size:16px;}
.left_nav dl.nav21.cur dt a{background:url("../img/user-2.png") 10px center no-repeat; background-size:16px;}

.left_nav dl.cur dd a{ display:block; height:34px; line-height:34px;border:1px solid #f6f6f6; font-size:14px; color:#666666; text-indent:70px; margin-bottom:5px;font-family:"HiraginoSansGB-W3"}
.left_nav dl.cur dd a img{ display:inline-block; width:20px; height:20px; margin-right:5px; vertical-align:-5px;}
.left_nav dl.cur dd a.Select{ border:1px solid #F0F0F0;border-radius:4px; height:34px; line-height:34px; background:#fff; color:#1a1a1a;font-family:"HiraginoSansGB-W6";font-weight:bold; }
.left_nav dl.cur dd a:hover{border:1px solid #F0F0F0;border-radius:4px; height:34px; line-height:34px; background:#fff; color:#1a1a1a;font-family:"HiraginoSansGB-W6";font-weight:bold;}
.left_nav dl.cur dd.tjht a{ text-indent:44px; overflow: hidden;text-overflow:ellipsis;white-space: nowrap;}

.left_nav dl.cur.con{border:1px solid #F0F0F0;border-radius:4px; height:34px; line-height:34px; background:#fff; color:#1a1a1a;font-family:"HiraginoSansGB-W6"; position:relative;font-weight:bold;}
.left_nav dl.cur.con dt span{ position:absolute; z-index:22; line-height:34px; top:0; left:75px; display:inline-block; width:100px; overflow:hidden;text-overflow:ellipsis;white-space: nowrap;}
.Middle_area{ width:624px;float:left; margin-left:32px; color:#1a1a1a;font-family:"HiraginoSansGB-W6";font-weight:bold;}
.Middle_left{  margin-left:238px;}
.wenda{border:1px solid #F0F0F0; background:#fff;padding:5px 20px 0;min-height:700px; border-radius:4px;}
.wenda dl{ border-bottom:1px solid #EFEFEF; padding:10px 0 7px; }
.wenda dl:nth-last-of-type(1){ border-bottom:none}
.wenda dl dt{ height:20px; line-height:20px; margin-bottom:10px;}
.wenda dl dt a{ font-size:14px; color:#999;font-family:"HiraginoSansGB-W3";margin-right:15px;display:inline-block; height:20px; line-height:20px}
.wenda dl dt a:hover{color:#6098f5}
.wenda dl dt img{ display:inline-block; width:20px; height:20px;border-radius:4px; vertical-align: middle; margin-right:7px;}
.wenda dl dt span{ color:#999; font-size:14px;  margin-right:15px;font-family:"HiraginoSansGB-W3"}
.wenda dl dt label{ color:#cccccc; font-size:14px;font-family:"HiraginoSansGB-W3"}
.wenda dl dd p.bold{line-height:24px; font-size:16px; color:#1a1a1a; margin-bottom:7px;font-family:"HiraginoSansGB-W3" }
.wenda dl dd p a{line-height:24px; font-size:16px; color:#1a1a1a;font-family:"HiraginoSansGB-W3"}
.wenda dl dd p a.Read{ color:#6098f5}
.wenda dl dd p.bold a{ font-size:16px; color:#1a1a1a; line-height:28px;}
.wenda dl dd p.bold a:visited { color:#4d4d4d}
.wenda dl dd p.bold a:hover{color:#6098f5}

.wenda dl dd a:hover{color:#6098f5}

.wenda dl dd p span.money{ font-size:14px; color:#fc6c37;line-height:28px;font-family:"HiraginoSansGB-W6";font-weight:bold; display:inline-block;background:url("../img/uub.png") left center no-repeat; padding-left:20px}

.wenda dl dd label{ color:#999; font-size:14px; margin-right:30px;font-family:"HiraginoSansGB-W3"; line-height:34px; cursor:pointer; padding-left:20px; }
.wenda dl dd label img{ margin-right:5px; vertical-align:-2px; width:14px;}
.wenda dl dd label.browse{background:url("../img/browse.png") left center no-repeat; background-size:14px; }
.wenda dl dd label.follow{background:url("../img/szs.png") left center no-repeat; background-size:14px;}
.wenda dl dd label.comment{background:url("../img/comment.png") left center no-repeat;background-size:14px; }

.wenda dl dd label.follow:hover{background:url("../img/szs2.png") left center no-repeat;background-size:14px; }
.wenda dl dd label.comment:hover{background:url("../img/comment2.png") left center no-repeat;background-size:14px; }

.wenda dl dd label.follow_hover{background:url("../img/szs2.png") left center no-repeat;background-size:14px; }
.wenda dl dd label.comment_hover{background:url("../img/comment2.png") left center no-repeat;background-size:14px; }

.wenda dl dd label.follow:visited{background:url("../img/szs.png") left center no-repeat; background-size:14px;}
.wenda dl dd label.comment:visited{background:url("../img/comment.png") left center no-repeat;background-size:14px; }

.wenda dl dd label i{ color:#666;font-family:"HiraginoSansGB-W3";}
.wenda dl dd a.n_page{ color:#999; font-size:14px; margin-right:30px;font-family:"HiraginoSansGB-W3"; line-height:34px;}
.wenda dl dd a.n_page i{ color:#666;font-family:"HiraginoSansGB-W3"}
.wenda dl dd a.n_page img{ margin-right:5px; width:14px;}


.right_area{ float:right; width:256px; margin-left:32px;}
.right_hd{position: fixed;z-index:40;top: 100px; left:50%}
.right_dw{ position:absolute; left:293px; width:100%}
.right_Notice{border:1px solid #EFEFEF; background:#fff; padding:22px 15px 25px; margin-bottom:10px;border-radius:4px;}
.right_Notice h4{ font-size:16px; color:#1A1A1A; height:30px;line-height:30px;font-family:"HiraginoSansGB-W6";font-weight:bold; margin-bottom:5px;}
.right_Notice h4 a{ float:right; color:#6098f5; font-size:14px;font-family:"HiraginoSansGB-W3" }
.right_Notice h4 a:hover{color:#80ADF7;}
.right_Notice ul li{ line-height:28px; height:28px; overflow:hidden;text-overflow:ellipsis;white-space: nowrap;}
.right_Notice ul li a{color:#4D4D4D; font-size:14px; font-family:"HiraginoSansGB-W3";}
.right_Notice ul li a:visited { color:#999}
.right_Notice ul li a:hover{color:#6098f5}
.right_Notice ul li a em.new{display:inline-block;background:url("../img/new.png") center center no-repeat; background-size:27px;  width:29px; height:16px;  line-height:16px;vertical-align:-3px; margin-right:5px;}
.right_Notice dl{ margin-top:20px;}
.right_Notice dl dt{ width:40px; height:40px;border-radius:4px; position:relative;  float:left}
.right_Notice dl dt a{width:40px; height:40px; line-height:40px; display:block;}
.right_Notice dl dt a img{width:40px; height:40px; line-height:40px;border-radius:4px; display:block;}
.right_Notice dl dt span{font-family:"HiraginoSansGB-W6";font-weight:bold;position:absolute; z-index:30; top:0; left:0; background:#6098f5;border-radius:4px; display:inline-block; width:18px; height:18px; text-align:center; line-height:18px; color:#fff; font-size:12px;}
.right_Notice dl dd{ float:left; margin-left:10px; width:160px;}
.right_Notice dl dd a{ font-size:16px; color:#1A1A1A; display:block; height:22px; line-height:22px;font-family:"HiraginoSansGB-W3" }
.right_Notice dl dd p{ color:#999; font-size:12px; line-height:18px;font-family:"HiraginoSansGB-W3" }
.right_Notice dl dd p i{ color:#666}
.az_download {padding:19px 20px ;}
.az_download dl{ margin-top:0; }
.az_download dl dt{ width:80px; height:80px;}
.az_download dl dt img{ width:80px; height:80px;}
.az_download dl dd{ width:117px;}
.az_download dl dd h5{ line-height:25px; font-size:14px; color:#1a1a1a;  margin-top:4px;font-family:"HiraginoSansGB-W3";margin-top:10px;}
.az_download dl dd p{ line-height:23px; font-size:12px; color:#999;font-family:"HiraginoSansGB-W3"}
.b_pp img{ width:216px; height:216px;}
.b_pp{ padding:19px 20px 14px;}
.foot_box{ background:#06152C; padding-top:30px;}

.foot_logo img{ height:31px;}
/*.foot_logo dl dt{ float:left; margin-right:10px;}
.foot_logo dl dt img{ width:72px;}
.foot_logo dl dd{ float:left;}
.foot_logo dl dd h4{ font-size:16px; color:#6098f5; line-height:20px;font-family:"HiraginoSansGB-W3"}
.foot_logo dl dd p{ font-size:14px; color:#6098f5; line-height:20px;font-family:"HiraginoSansGB-W3"}*/
.foot_link{ width: 870px;float: left;padding: 5px 0 20px; margin-top:18px;}
.foot_link ul li{ width:135px; margin-right:10px; margin-bottom:10px; float:left;}
.foot_link a{ font-size:12px; color:#A6A7AA ; line-height:22px; margin-right:18px; display:inline-block;font-family:"PingFangSC-Regular"}
.foot_link a:hover{color:#6098f5}
.foot_link ul li.sqlj a{color:#6095F2}
.Customer_service{ width:250px; float:left; margin-top:27px}
.Customer_service dl{ float:right; width:80px; margin:0 13px 0 21px}
.Customer_service dl dt{ width:80px; height:80px; overflow:hidden}
.Customer_service dl dt img{ width:80px; height:80px;}
.Customer_service dl dd{ font-size:12px; color:#A6A7AA; text-align:center; line-height:20px;font-family:"PingFangSC-Regular"}
.foot_bottom{ margin-top:4px;}
.foot_bottom p{ font-size:12px; color:#CCCCCC; line-height:25px; text-align:center;font-family:"PingFangSC-Regular"}
.foot_bottom p i{ color:#fff; font-size:12px;}
em.ding{ display:inline-block;background:url("../img/top.png") center center no-repeat; background-size:29px;  width:29px; height:16px;  line-height:16px;vertical-align:-2px; margin-right:8px;}

/*翻页*/
.mbw{ margin:20px 0;}
.pg{ text-align:right}
.pg,.pgb{line-height:32px;}
.pg a,.pg strong{display:inline-block;padding:0 5px;height:32px; min-width:24px; font-size:12px; text-align:center;border-right:1px solid #F0F0F0;background:#fff;color:#cccccc;overflow:hidden;font-family:"HiraginoSansGB-W3"}
.pg a:hover{color:#fff;background:#6098f5;}
.pg strong{background-color:#EA3D1F;}
.pgb a{padding-left:25px;}
.pg label{cursor:text;}
.ie6 .pg label{padding-top:3px;height:23px;}
.pg label .px{padding:0;width:25px;height:16px;line-height:16px;}
#pgt .pg,#pgt .pgb{margin-top:5px;}
.pg a.active{color:#fff;background:#6098f5;}
.pg a.prev,.pg a.nxt{border-radius:4px;border:1px solid #F0F0F0;}
.pg span{border:1px solid #F0F0F0; display:inline-block; height:32px; line-height:32px; margin:0 5px;border-top-left-radius:4px;border-bottom-left-radius:4px;border-top-right-radius:4px;border-top-right-radius:4px }
.pg span a:nth-last-of-type(1){ border-right:none}

/*私信*/
.left_nav dl.nav6.con dt a{ border:1px solid #F0F0F0; background-color:#fff;font-family:"HiraginoSansGB-W6";font-weight:bold; color:#333}
.help{ float:right;}
.help ul li{ float:right; width:38px; height:68px; line-height:68px; text-align:center; position:relative; border-left:1px solid #fff;border-right:1px solid #fff;}
.help span{ font-size:16px; color:#666; font-family:"HiraginoSansGB-W3"; width:38px; height:68px; line-height:68px;  display:block;text-align:center;  cursor:pointer; position:absolute; z-index:44  }
.help span em{ display:inline-block; min-width:10px; height:16px; line-height:16px; padding:0 3px; color:#fff; background:#FC6C38; text-align:center; border-radius:16px; font-size:10px; position:relative; top:-10px; right:-8px;}
.help ul li.t_notice span{background:url("../img/t_n1.png") center center no-repeat; background-size:18px;  }
.help ul li.t_message span{background:url("../img/t_m1.png") center center no-repeat; background-size:18px; }
.help ul li.t_personal{width:50px}
.help ul li.t_personal span{ width:50px;  }
.help ul li.t_personal span:hover{background:#fff;}
.help ul li.t_personal.cur span{height:69px;background:#fff;}
.help ul li.t_personal span img{ width:30px; height:30px; line-height:30px; border-radius:4px; display:inline-block; margin-top:19px;}
.help ul li.t_notice:hover span{background:#fff url("../img/t_n2.png") center center no-repeat; background-size:18px; }
.help ul li.t_message:hover span{background:#fff url("../img/m_pre.png") center center no-repeat;background-size:18px; }
.help ul li.t_notice.cur span{background:#fff url("../img/t_n2.png") center center no-repeat; background-size:18px;}
.help ul li.t_message.cur span{background:#fff url("../img/t_m2.png") center center no-repeat; background-size:18px;}
.help ul li.cur{ border-left:1px solid #f0f0f0;border-right:1px solid #f0f0f0;}
.per_but{ position:absolute; z-index:22; top:69px; right:-1px; width:80px;border:1px solid #f0f0f0; border-top:none;border-radius:4px; display:none}
.per_but a{ display:block; font-size:14px; background:#fff; color:#6098f5; text-align:center; height:39px; line-height:39px;font-family:"HiraginoSansGB-W3";}
.per_but a img{ vertical-align:middle; margin-right:5px; width:16px; }
.per_but a:hover{ background:#fafafa}
.Private_letter{ width:360px; border:1px solid #f0f0f0;  position:absolute; z-index:33; top:67px; right:-160px; background:#fff;border-radius:4px;}
.no_data{ height:78px; line-height:78px; text-align:center; font-size:16px; color:#CDCDCD;font-family:"HiraginoSansGB-W3" ;}
.letter_list{text-align:left;padding:10px 20px; height:60px;font-size:14px; color:#1A1A1A;font-family:"HiraginoSansGB-W3" ; cursor:pointer}
.letter_list a{ font-size:14px; color:#1A1A1A; float:left; height:30px; line-height:30px; margin-right:5px; display:inline-block; overflow: hidden;text-overflow:ellipsis;white-space: nowrap;font-family:"HiraginoSansGB-W3" ;}
.letter_list a img{ display:inline-block; width:30px; height:30px;border-radius:4px; margin-right:8px; vertical-align:-11px;}
.letter_list a i{ color:#6098f5;font-family:"HiraginoSansGB-W6";font-weight:bold;  display:inline-block; max-width:68px;overflow: hidden;text-overflow:ellipsis;white-space: nowrap; }
.letter_list a.info { max-width:88px;color:#6098f5; margin:0 2px;}
.letter_list p{ height:25px; line-height:25px; clear:both}
.help ul li.t_notice.cur .letter_list p span{ height:25px; width:auto; background:none; line-height:25px; color:#ccc; font-size:12px;font-family:"HiraginoSansGB-W3";}
.letter_list label{ float:left;height:30px;  line-height:30px; color:#1A1A1A; font-size:14px;font-family:"HiraginoSansGB-W3";}
.letter_list p label.fr{ height:25px; float:right; line-height:25px; color:#999; font-size:12px;font-family:"HiraginoSansGB-W3";}
.letter_list:hover{ background:#fafafa;}
.letter_list label.info{ max-width:203px; height:30px; line-height:30px;font-size:14px; color:#1A1A1A;font-family:"HiraginoSansGB-W3" ; display:inline-block; float:left;overflow: hidden;text-overflow:ellipsis;white-space: nowrap;}
.l_more{ height:39px; line-height:39px; border-top:1px solid #f0f0f0; text-align:center;}
.l_more button{font-size:14px; color:#ccc;font-family:"HiraginoSansGB-W3" ; display:block; border:none; text-align:center; background:none; height:39px; line-height:39px; width:358px; cursor:pointer}
.l_more button:hover{ color:#6098f5}
.help a:hover{ color:#6098f5}
.Launch{ float:left; margin-top:17px; margin-left:30px;background:#6098f5; line-height:34px; width:80px;border-radius:4px;  position:relative; z-index:1}
.Launch span{ display:block; color:#fff; font-size:12px; font-family:"HiraginoSansGB-W3" ; text-align:center; cursor:pointer }
.l_but{ position:absolute; z-index:333; top:34px; left:0; width:100%;background:#6098f5;border-bottom-left-radius:4px;border-bottom-right-radius:4px; display:none;transition: .8s;}
.l_but a{ display:block; color:#fff; line-height:40px; font-size:12px; font-family:"HiraginoSansGB-W3" ; text-align:center; }
.l_but a:hover{ background:#7facf6}
.l_but a img{ vertical-align:middle; margin-right:5px; display:inline-block; width:16px; height:16px}
.Launch:hover{border-bottom-left-radius:0;border-bottom-right-radius:0;}
.Launch:hover .l_but{ display:block}
.wenda dl dt label.fr{ float:right; color:#ccc;font-family:"HiraginoSansGB-W3"; font-size:12px;}
.Topic{ margin-top:5px;}
.Topic a{font-family:"HiraginoSansGB-W3"; color:#6098f5; border:1px solid #6098f5;border-radius:12px; font-size:12px; height:22px; line-height:22px; padding:0 9px; margin:5px 8px 5px 0;  display:inline-block;}
.Topic a:hover{ background:#eff4fe}
.P_letter dl{ padding-bottom:21px;}
.P_letter dl dt{ margin-bottom:18px;}
.P_letter .foot_bq{ margin-top:-5px;}
.P_letter .foot_bq label:first-of-type{ padding-left:0}
.P_letter dl dd span.time{ display:inline-block; font-size:12px; color:#999; line-height:34px;font-family:"HiraginoSansGB-W3"; }
.P_letter dl dd a.reply{font-size:14px; color:#6098f5; line-height:34px;font-family:"HiraginoSansGB-W3";}
.sdsm{ border-bottom:1px solid #f0f0f0; padding:15px 0 20px;}
.sdsm h3{ font-size:16px; color: #1A1A1A; line-height:30px;font-family:"HiraginoSansGB-W6";font-weight:bold; margin-bottom:14px; }
.sdsm input{border:1px solid #E6E6E6; height:46px; line-height:46px; padding:0 5px; color: #1a1a1a; font-size:16px;font-family:"HiraginoSansGB-W3"; width:572px; margin-bottom:20px;border-radius:4px;}
.sdsm textarea{border:1px solid #E6E6E6; height:46px; line-height:46px; padding:0 5px; color: #1a1a1a; font-size:16px;font-family:"HiraginoSansGB-W3"; width:572px; margin-bottom:20px;border-radius:4px;}
.sdsm a.reply{font-size:14px; background: #EFF4FE; color:#6098f5;font-family:"HiraginoSansGB-W3"; display:block; width:120px; height:34px; line-height:34px; text-align:center;border-radius:4px;}
.sdsm a.reply:hover{background: #6098f5; color:#fff;}

/*帮助中心*/
.Help_center{border:1px solid #F0F0F0; background:#fff;padding:5px 20px 0; min-height:700px;border-radius:4px;}
.Help_center h3{ font-size:16px; color:#1a1a1a; line-height:30px; text-align:center; margin:20px 0;font-family:"HiraginoSansGB-W6";font-weight:bold;}
.Help_center input{ height:32px; line-height:32px; border:1px solid #cccccc; border-radius:4px; width:434px; padding:0 5px;font-family:"HiraginoSansGB-W3"; color:#ccc; font-size:14px;}
.Help_center button{font-family:"HiraginoSansGB-W3"; background:#6098f5; color:#fff; font-size:12px; height:34px; border:none; margin-left:10px; line-height:34px; width:120px; text-align:center; display:inline-block;border-radius:4px;}

/*通知*/
.y_notice{border:1px solid #F0F0F0; background:#fff;padding:5px 20px 0; min-height:700px;border-radius:4px;}
.y_notice ul li{ list-style-type:none}
.y_notice ul li {  line-height:30px;border-bottom:1px solid #F0F0F0; padding:19px 0 23px}
.y_notice ul li em{ display:inline-block; width:6px; height:6px; border-radius:6px; background:#FC6C38; margin-right:10px;}
.y_notice ul li:nth-last-of-type(1){ border-bottom:none}
.y_notice ul li a{font-size:14px; color:#333333;font-family:"HiraginoSansGB-W6";font-weight:bold; }
.y_notice ul li a:visited { color:#999}
.y_notice ul li a:hover{ color:#6098f5; }
.y_notice ul li span{ color:#333; font-size:14px; margin:0 4px;font-family:"HiraginoSansGB-W3";}
.y_notice ul li label{ color:#ccc; font-size:12px; float:right;font-family:"HiraginoSansGB-W3";}
.y_notice ul li p label.browse{ float:none;background:url("../img/browse.png") left center no-repeat; background-size:14px; padding-left:20px;color:#999; font-size:14px; }

/*专栏*/
.Special_column{border:1px solid #F0F0F0; background:#fff;padding:5px 20px 0; min-height:700px;border-radius:4px;}
.Special_column dl{ border-bottom:1px solid #EFEFEF; padding:25px 0;}
.Special_column dl:nth-last-of-type(1){ border-bottom:none}
.Special_column dl dt{ height:60px; line-height:60px; margin-right:15px; float:left; position:relative; z-index:1 }
.Special_column dl dt img{ display:inline-block; width:58px; height:58px;border-radius:4px; vertical-align: middle; }
.Special_column dl dd{ float:left; width:357px; margin-right:20px;}
.Special_column dl dd span.money{ font-size:14px; color:#FC6C38;line-height:28px;  display:inline-block;background:url("../img/uub.png") left center no-repeat; padding-left:20px;font-family:"HiraginoSansGB-W6";font-weight:bold;}
.Special_column dl dd a{ font-size:18px; color:#333333; margin-right:10px;font-family:"HiraginoSansGB-W6";font-weight:bold; margin-bottom:5px; display:inline-block; line-height:30px; }
.Special_column dl dd label{ color:#808080; font-size:14px; display:block; margin-bottom:3px;font-family:"HiraginoSansGB-W3";}
.Special_column dl dd p{ font-size:14px; color:#4D4D4D; line-height:24px;font-family:"HiraginoSansGB-W3";}
.Special_column dl dd p i{ color:#1A1A1A; margin:0 5px; font-style:normal}
.Special_column dl dd.fr{ float:right; width:120px; margin-right:0}
.Special_column dl dd.fr button{ cursor:pointer; margin-top:14px; background:#EFF4FE;font-family:"HiraginoSansGB-W3"; color:#6098f5; font-size:14px; height:34px; border:none; margin-left:0; line-height:34px; width:120px; text-align:center; display:inline-block;border-radius:4px;}
.Special_column dl dd.fr button:hover{ color:#fff; background:#80ADF7}
.Special_column dl dd.fr button.active{ color:#fff; background:#6098f5}

/*动态*/
.Agree_with{ color:#4D4D4D; font-size:12px; line-height:25px;font-family:"HiraginoSansGB-W3";}
.Agree_with a{color:#4D4D4D; font-size:12px; line-height:25px;font-family:"HiraginoSansGB-W3";}
.Agree_with a.more{ color:#6098f5; margin-left:3px;}
.wenda dl dd p{line-height:24px; font-size:16px; color:#1a1a1a; margin-bottom:6px;font-family:"HiraginoSansGB-W3" }
.zkzd{ float:left; margin-right:20px;}
.zkzd span{ border:1px solid #6098f5; cursor:pointer; color:#6098f5;font-family:"HiraginoSansGB-W3";border-radius:4px;min-width:16px;height:26px; display:inline-block; padding:0 10px; line-height:26px; text-align:center; margin-right:6px;}
.zkzd span.Step_on{ background:#6098f5; color:#fff;}
.zkzd span.Step_on em.up{background:url("../img/z2.png") left center no-repeat;background-size:12px;}
.zkzd span:hover{ background:#6098f5; color:#fff;}
.zkzd span em{width:12px;height:12px; display:inline-block; margin:0 auto; margin-right:10px; cursor:pointer }
.zkzd span em.up{background:url("../img/z.png") left center no-repeat; background-size:12px;}
.zkzd span:hover em.up{background:url("../img/z2.png") left center no-repeat;background-size:12px;}
.zkzd span.s_up{ background:#6098f5; color:#fff;}
.zkzd span.s_up em.down{background:url("../img/d2.png") left center no-repeat;background-size:12px;}
.zkzd span em.down{background:url("../img/d.png") left center no-repeat;background-size:12px; /* margin:0 auto */}
.zkzd span:hover em.down{background:url("../img/d2.png") left center no-repeat;background-size:12px;}
.zkzd span.s_up em.downs{background:url("../img/d2.png") left center no-repeat;background-size:12px;}
.zkzd span em.downs{background:url("../img/d.png") left center no-repeat;background-size:12px; }
.zkzd span:hover em.downs{background:url("../img/d2.png") left center no-repeat;background-size:12px;}

.zkzd span.grayon{ background:#c9c9ca; color:#fff; border:1px solid #c9c9ca}
.zkzd span.grayon em.down{background:url("../img/d2.png") left center no-repeat;background-size:12px;}

.zkzd span.grayon{ background:#c9c9ca; color:#fff; border:1px solid #c9c9ca}
.zkzd span.grayon em.up{background:url("../img/z2.png") left center no-repeat;background-size:12px;}

.personal{border:1px solid #EFEFEF; background:#fff; padding:22px 15px 25px; margin-bottom:10px;border-radius:4px;}
.personal dl { border-bottom:1px solid #F0F0F0; padding-bottom:15px; margin-bottom:13px; margin-top:8px;}
.personal dl dt{width:100px;height:100px; border-radius:4px;margin:0 auto 10px;}
.personal dl dt img{width:100px;height:100px; border-radius:4px; margin:0 auto; display:block}
.personal dl dd{ text-align:center; font-size:16px; color:#1A1A1A;font-family:"HiraginoSansGB-W6";font-weight:bold;}
.personal dl dd a{ font-size:16px; color:#1A1A1A;font-family:"HiraginoSansGB-W6";font-weight:bold;}
.personal ul li{ width:50%; float:left; text-align:center}
.personal ul li span{ display:block; color:#85A6A2; font-size:12px;font-family:"HiraginoSansGB-W3";font-weight:bold; line-height:23px;}
.personal ul li label{ display:block;color:#333333; font-size:14px;font-family:"HiraginoSansGB-W6";font-weight:bold; line-height:23px;}
.Invitation_but { margin-top:15px; margin-bottom:3px;}
.Invitation_but button{ background:#6098f5; color:#fff; font-size:14px; height:34px;font-family:"HiraginoSansGB-W3" ; border:none; cursor:pointer; line-height:34px; width:100%; text-align:center; display:inline-block;border-radius:4px;}

/*我的收藏*/
.biaoq { margin-top:10px;}
.biaoq a{min-width:26px;height:22px; padding:0 9px; border:1px solid  #6098f5;border-radius:12px; display:inline-block; float:left; margin:0 10px 10px 0; font-size:12px; color:#6098f5;font-family:"HiraginoSansGB-W3"; text-align:center; line-height:22px;}
.biaoq a:hover{ background:#eff4fe}
.No_more{ color:#CDCDCD; font-size:16px; border-top:1px solid #EFEFEF; padding:30px 0;font-family:"HiraginoSansGB-W3";}
.Special_column dl dd.fr ul{list-style-type:none;}
.Special_column dl dd.fr ul li{ width:49%; float:left; text-align:center; border-right:1px solid #EFEFEF;list-style-type:none;}
.Special_column dl dd.fr ul li:nth-last-of-type(1){border-right:none}
.Special_column dl dd.fr ul li span{ display:block; font-size:14px; color:#ccc;font-family:"HiraginoSansGB-W3"; line-height:20px;}
.Special_column dl dd.fr ul li  b{display:block; font-size:16px; color:#1A1A1A;font-family:"HiraginoSansGB-W6";font-weight:bold; line-height:25px; font-weight:normal}
.biaoq a.gray_edit{ border:1px solid #979799; color:#999999;}
.biaoq a.gray_edit i{ display:inline-block; width:14px; height:14px; cursor:pointer; margin-left:3px; vertical-align:-2px;}
.biaoq a.gray_edit i img{ display:inline-block; width:14px; height:14px; }
.Collection_folder { margin-bottom:15px; position:relative}
.Collection_folder input{border:1px solid #E5E5E5; height:32px; line-height:32px; padding:0 5px; color: #CCCCCC; font-size:14px;font-family:"HiraginoSansGB-W3"; width:460px; border-radius:4px;}
.Collection_folder a.cj{cursor:pointer; background:#6098F5;font-family:"HiraginoSansGB-W3"; color:#fff; font-size:14px; height:34px; border:none; margin-left:10px; line-height:34px; width:100px; text-align:center; display:inline-block;border-radius:4px;}
.ser_list{border:1px solid  #F0F0F0; position:absolute; z-index:22; top:54px; left:0; width:100%; background:#fff; max-height:200px; overflow:auto}
.ser_list ul li{ height:40px; line-height:40px; overflow:hidden}
.ser_list ul li a{ display:block; color: #1A1A1A; font-size:14px;overflow: hidden;text-overflow:ellipsis;white-space: nowrap; padding:0 18px;}
.ser_list ul li a:hover{ background: #FAFAFA}
.Problem_label{border-bottom:1px solid #F0F0F0; margin:0 -20px; padding:23px 20px 20px;}
.Problem_label span{font-family:"HiraginoSansGB-W3"; cursor:pointer; color:#6098f5; border:1px solid #EFF4FE; background:#EFF4FE;border-radius:12px; font-size:12px; height:22px; line-height:22px; padding:0 9px; margin:0 10px 10px 0;  display:inline-block;}
.Problem_label span.cur{ border:1px solid #6098F5;}
.Problem_label span:hover{border:1px solid #6098F5;}
.Problem .biaoq a{border:1px solid #EFF4FE; background:#EFF4FE;}
.Problem .biaoq a:hover{border:1px solid #6098F5;}

/*发起文章*/
.Launch_box{border:1px solid #F0F0F0; background:#fff;padding:5px 20px 0; min-height:700px;border-radius:4px;}
.Launch_box h3{ color:#1A1A1A; font-size:16px;font-family:"HiraginoSansGB-W6";font-weight:bold; line-height:30px; margin-bottom:12px; margin-top:22px;}
.Launch_box input{height:46px; line-height:46px; border:1px solid #E6E6E6; border-radius:4px; width:570px; padding:0 5px;font-family:"HiraginoSansGB-W3"; color:#1A1A1A; font-size:16px;}
.Paran_text i{ font-style:normal}
input::-webkit-input-placeholder{color:#ccc;}
input::-moz-placeholder{color:#ccc;}
input:-moz-placeholder{color:#ccc;}
input:-ms-input-placeholder{color:#ccc;}


.pt_upload{width:138px; height:98px;  position:relative; z-index:1;  border:1px solid #F0F0F0}
.pt_upload span{width:140px; height:100px; position: absolute; z-index: 22; display:inline-block;left:0; top:0; }
.pt_upload img{width:30px; height:30px;display:block;margin:34px auto 0 }
.pt_upload input.uphead{width:140px; height:92px;display: block; padding:0; border:none; position: absolute; z-index: 666; left:0; top:0;  opacity: 0;filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0); cursor:pointer}
.Launch_box p{ font-size:12px; color:#999; line-height:20px;font-family:"HiraginoSansGB-W3";}
.topic_label span{ height:28px; line-height:28px; margin:10px 6px 0 0; border:1px solid #6098f5;border-radius:15px; padding:0 15px; display:inline-block; color:#6098f5; font-size:14px;font-family:"HiraginoSansGB-W3"; }
.topic_label span em{ margin-left:5px; cursor:pointer}

.Add_topic{ position:relative;}
.Add_topic .ser_list{ width:520px;}
.Add_topic input{  border:1px solid #6098f5; height:30px; line-height:30px; width:564px; padding:8px;border-radius:4px;}
.Add_topic input::-webkit-input-placeholder{color:#ccc;}
.Add_topic input::-moz-placeholder{color:#ccc;}
.Add_topic input:-moz-placeholder{color:#ccc;}
.Add_topic input:-ms-input-placeholder{color:#ccc;}



.Add_topic input.w500{ width:504px;}
.Add_topic a{ color:#6098F5; font-size:14px;line-height:48px;font-family:"HiraginoSansGB-W3"; margin-left:15px;}
.latest_topic a{ height:22px; line-height:22px; border:1px solid #6098f5;border-radius:12px; padding:0 10px; margin:0 10px 10px 0; display:inline-block; color:#6098f5; font-size:14px;font-family:"HiraginoSansGB-W3";}
.Put_questions{ margin-bottom:30px;}
.Put_questions span{ font-size:14px; color:#333; line-height:25px;font-family:"HiraginoSansGB-W3"; margin-right:20px;}
.Put_questions span em{ display:inline-block; width:10px; height:10px; border:1px solid #6098f5;border-radius:2px; vertical-align:middle; margin-right:5px;}
.Put_questions span.cur em{background:url("../img/gou.png") left center no-repeat;background-size: 10px;}
.Put_questions a{font-family:"HiraginoSansGB-W3"; float:right; background:#6098f5; color:#fff; font-size:12px; height:34px; border:none; margin-left:10px; line-height:34px; width:120px; text-align:center; display:inline-block;border-radius:4px;}
.guide{border:1px solid #EFEFEF; background:#fff; padding:22px 15px 25px; margin-bottom:20px;border-radius:4px;}
.guide h4{ font-size:16px; color:#1A1A1A; height:30px;line-height:30px;font-family:"HiraginoSansGB-W6";font-weight:bold; margin-bottom:5px;}
.guide p{ line-height:20px; font-size:14px; color:#4D4D4D;font-family:"HiraginoSansGB-W3"; margin-bottom:20px;}
.guide b{ color:#1A1A1A}

.Launch_box .topic_label span{  margin:3px 10px 7px 0}
.pic_code{ margin:20px 0;}
.pic_code input{height:46px; line-height:46px; border:1px solid #E6E6E6; float:left; margin-right:10px; border-radius:4px; width:114px; padding:0 5px;font-family:"HiraginoSansGB-W3"; color:#1A1A1A; font-size:16px;}

.pic_code a{ display:inline-block; width:90px; height:48px;}

/*个人中心页*/
.personal_top{height:160px;background:#fff; padding:30px 0 10px}
.personal_top dl dt{ width:100px; height:100px; float:left; margin-left:260px; margin-top:10px; margin-right:32px; border-radius:4px}
.personal_top dl dt a{ width:100px;height:100px; display:inline-block; line-height:100px;border-radius:4px}
.personal_top dl dt a img{ width:100px; height:100px;display:inline-block;border-radius:4px }
.personal_top dl dd{ float:left; width:450px; margin-top:5px; padding-right:28px;}
.personal_top dl dd.but{ width:280px; margin-top:31px;padding-right:0;}
.personal_top dl dd h1{ font-size:22px; color: #262626; line-height:30px;font-family:"HiraginoSansGB-W6";font-weight:bold; margin-bottom:2px;}
.personal_top dl dd h1 span.money{ margin-left:18px;font-size:14px; color:#fc6c37;line-height:28px;font-family:"HiraginoSansGB-W6";font-weight:bold;display:inline-block;background:url("../img/uub.png") left center no-repeat; padding-left:22px; vertical-align:3px;}
.personal_top dl dd h5{font-size:14px; color:#676767; line-height:30px;font-family:"HiraginoSansGB-W3"; margin-bottom:7px; }
.personal_top dl dd h4{ font-size:16px; color:#1A1A1A; line-height:30px;font-family:"HiraginoSansGB-W3"; margin-bottom:3px; }
.personal_top dl dd p span{font-size:12px; color:#85A6A2; line-height:30px;font-family:"HiraginoSansGB-W3"; margin-right:42px; }
.personal_top dl dd p span i{ color:#333333; font-size:16px;font-family:"HiraginoSansGB-W3"; margin-right:12px;}
.personal_top dl dd.but a{font-family:"HiraginoSansGB-W3"; float:right; color:#6098f5; font-size:12px; height:32px; border:1px solid #6098f5; margin-left:10px; line-height:32px; width:118px; text-align:center; display:inline-block;border-radius:4px;}
.personal_top dl dd.but a:hover{ background:#6098f5; color:#fff;}
.personal_top dl dd.but a.gz_but{ background:#6098f5; color:#fff;}
.personal_top dl dd.but a.w100{ width:102px;}
.personal_top dl dd.but a.w60{ width:58px;}
.person_concern{border:1px solid #EFEFEF; background:#fff; padding:22px 15px 17px; margin-bottom:10px;border-radius:4px}
.person_concern h4{ font-size:16px; color:#1A1A1A; height:30px;line-height:30px;font-family:"HiraginoSansGB-W6";font-weight:bold; margin-bottom:13px;}
.person_concern h4 a{ float:right; color:#6098f5; font-size:14px;font-family:"HiraginoSansGB-W3" }
.person_concern ul {list-style-type:none;}
.person_concern ul li{list-style-type:none;}
.person_concern ul li{ width:24px; height:24px; float:left; margin:0 12px 12px 0;}
.person_concern ul li a{ width:24px; height:24px; line-height:24px;}
.person_concern ul li a img{ width:24px; height:24px; line-height:24px;border-radius:4px}
.t_line{ border-top:1px solid #EFEFEF;  margin:18px 0 12px;}
.h_pic {width:58px; height:58px;border-radius:4px; float:left;margin-right:15px;}
.h_pic img{ display:inline-block; width:58px; height:58px;border-radius:4px; vertical-align: middle; }
.h_text{ float:left; width:357px; margin-right:20px;}
.h_text a{ font-size:18px; color:#333333;font-family:"HiraginoSansGB-W6";font-weight:bold;}
.h_text a i.colour{ color:#6098f5}
.wenda dl dd .h_text p{ font-size:14px; color:#4D4D4D; line-height:24px;font-family:"HiraginoSansGB-W3"; margin-bottom:0; margin-top:3px;}
.h_text p i{ color:#1A1A1A; margin:0 3px; font-style:normal}
.follow_BUT { float:left; width:130px;}
.follow_BUT button{ cursor:pointer; float:left;margin-top:14px; background:#EFF4FE;font-family:"HiraginoSansGB-W3"; color:#6098f5; font-size:14px; height:34px; border:none; margin-left:10px; line-height:34px; width:120px; text-align:center; display:inline-block;border-radius:4px;}
.follow_BUT a{ cursor:pointer; float:left;margin-top:14px; background:#EFF4FE;font-family:"HiraginoSansGB-W3"; color:#6098f5; font-size:14px; height:34px; border:none; margin-left:10px; line-height:34px; width:120px; text-align:center; display:inline-block;border-radius:4px;}
.wenda dl dd .follow_BUT a:hover{ background:#80ADF7; color:#fff;}
.wenda dl dd .follow_BUT a.hover{ background:#80ADF7; color:#fff;}
.wenda dl dd .follow_BUT a:active{ background:#6098F5; color:#fff;}
.wenda dl dd .follow_BUT a.active{ background:#6098F5; color:#fff;}
.follow_BUT button:hover{ background:#80ADF7;color:#fff;}
.follow_BUT button.hover{ background:#80ADF7;color:#fff;}
.wenda dl dd .follow_BUT button:active{ background:#6098F5; color:#fff;}
.wenda dl dd .follow_BUT button.active{ background:#6098F5; color:#fff;}
.h_text span.money{font-size:14px; color:#FC6C38; margin-left:15px;  display:inline-block;background:url("../img/uub.png") left center no-repeat; padding-left:20px;font-family:"HiraginoSansGB-W6";font-weight:bold;}
.wenda dl dd .h_text label{ color:#808080; font-size:14px; display:block; margin-bottom:3px;font-family:"HiraginoSansGB-W3"; line-height:20px; padding-left:0}
.personal_s dl{ padding:14px 0 30px }
.personal_s dl dt{ margin-bottom:5px;}
.personal_s dl dt a{ margin-right:4px;}
.personal_s .foot_bq{ margin-top:16px;}
.personal_s dl.gzht{ padding:20px 0}
.personal_s dl.gzht dt{ margin-bottom:13px}
.personal_s dl.gzht dd p{ margin-top:8px;}

/*公告详情页*/
.Notice_Details{border:1px solid #F0F0F0; background:#fff;padding:5px 20px 0; min-height:700px;border-radius:4px;}
.notice_title{margin-top:17px;}
.notice_title h3{ font-size:22px; color: #262626; line-height:30px; text-align:center;font-family:"HiraginoSansGB-W6";font-weight:bold; }
.notice_title p{ color:#CCCCCC; font-size:12px; text-align:center;font-family:"HiraginoSansGB-W3"; margin:11px 0 15px}
.Paran_box{ color: #666;font-size: 16px;line-height: 28px; font-family:"Microsoft Yahei"}
.Paran_box img{ max-width:580px;}

/*话题详情页*/
.personal_top dl dd p{ color:#262626; font-size:16px; line-height:24px;font-family:"HiraginoSansGB-W3";}
.personal_top dl dd p a{color:#6098f5;}
.right_ser{border:1px solid #F0F0F0; background:#fff;padding:21px 20px 26px;  border-radius:4px;margin-bottom:20px;}
.right_ser .top_search{ float:none; position:static; width:auto; margin-top:0; height:30px; position:relative}
.right_ser .top_search input{ width:194px;}
.right_ser .top_search button{ margin-top:7px;}
.right_ser .search_result{ width:208px;}
.right_ser .search_result{ top:31px;}
.ht_list dl{ padding:20px 0 41px}
.ht_list dl dd a{ margin-top:2px;}
.ht-list .foot_bq{ margin-top:8px;}
.ht-list dl{ padding-bottom:20px;}
.ht-list .comment_box{ margin-top:20px;}

/*专栏详情页图文版*/
.Column_tp{border:1px solid #F0F0F0; background:#fff;padding:20px 20px 0; min-height:700px;border-radius:4px;}
.Column_tp dl{border-bottom:1px solid #EFEFEF; padding:10px 0 9px;margin-bottom:12px;}
.Column_tp dl dt{ float:left; width:396px;}
.Column_tp dl dt a{font-size:16px; color:#1a1a1a; height:22px; line-height:22px;display:block;font-family:"HiraginoSansGB-W6";font-weight:bold; margin-bottom:8px;overflow: hidden;text-overflow:ellipsis;white-space: nowrap;}
.Column_tp dl dt a:visited{ color:#4d4d4d;}
.Column_tp dl dt a:hover{ color:#6098f5;}
.Column_tp dl dt p{ color:#1A1A1A; font-size:14px;font-family:"HiraginoSansGB-W3"; line-height:20px; height:60px; overflow:hidden; margin-bottom:14px;}
.Column_tp dl dd{ width:168px; height:104px;margin-left:18px; float:left}
.Column_tp dl dd img{width:168px; height:104px; }
.Column_tp dl dt label{ color:#999; font-size:14px; margin-right:30px;font-family:"HiraginoSansGB-W3"; line-height:34px;}
.Column_tp dl dt label i{ color:#666;font-family:"HiraginoSansGB-W3"}
.Column_tp dl dt label img{ margin-right:5px; width:14px;}
.Column_tp dl dt label.fr{font-size:12px; color:#CCCCCC;font-family:"HiraginoSansGB-W3";  margin-right:0}
.wenda dl dd label.fr{ font-size:12px; color:#CCCCCC;font-family:"HiraginoSansGB-W3"; margin-right:0}
.wenda dl dd p.bold a i.colour{ color:#6098f5;}

/*设置_安全设置*/
.Password_setting{border:1px solid #F0F0F0; background:#fff;padding:5px 20px 0; min-height:700px;border-radius:4px;}
.Password_setting h3{ font-size:16px; color:#333; line-height:30px;font-family:"HiraginoSansGB-W6";font-weight:bold; margin:18px 0 12px;}
.Password_setting dl{ margin-bottom:10px;}
.Password_setting dl dt{ float:left; width:90px; line-height:34px; font-size:12px; color:#999;font-family:"HiraginoSansGB-W3";}
.Password_setting dl dd{ float:left; width:490px;}
.Password_setting dl dd input{ height:32px; line-height:32px; border:1px solid  #E6E6E6;border-radius:4px; padding:0 5px; width:230px;}
.Password_setting dl dd input:focus{ border:1px solid #6098f5}
.bc_but a{font-family:"HiraginoSansGB-W3"; margin:0px auto; background:#6098f5; color:#fff; font-size:14px; height:34px; border:none;  line-height:34px; width:120px; text-align:center; display:block;border-radius:4px;}

/*基本资料*/
.Basic_information{border:1px solid #F0F0F0; background:#fff;padding:5px 20px 20px; min-height:700px;border-radius:4px;}
.Basic_information dl { margin-bottom:29px;}
.Basic_information dl:first-of-type{ margin-top:22px;}
.Basic_information dl:first-of-type dt{ line-height:100px;}
.Basic_information dl dt{ width:70px; float:left; line-height:34px; font-size:14px; color:#333;font-family:"HiraginoSansGB-W3";}
.Basic_information dl dd{ width:492px; margin-left:20px;line-height:34px; color:#262626; font-size:14px;font-family:"HiraginoSansGB-W3"; float:left;}
.Basic_information dl dd img{ height:100px; width:100px; display:inline-block; float:left; margin-right:30px;}
.Basic_information dl dd .pt_upload{ float:left; width:88px; height:34px; line-height:34px; text-align:center;border-radius:4px;border:none; margin-top:35px;}
.Basic_information dl dd .pt_upload span{ display:inline-block;width:88px; height:34px; line-height:34px; text-align:center;border-radius:4px; background:#EFF4FE; cursor:pointer; color:#6098f5}
.Basic_information dl dd .pt_upload input{display:inline-block;width:88px; height:34px; line-height:34px; text-align:center;border-radius:4px; }

.Basic_information dd label.cf1{ text-align:center; line-height:34px;  font-size:14px; margin-right:40px; font-weight:normal; margin-bottom:0; float:left; cursor:pointer}
.Basic_information dd label i{width: 12px;height: 12px;border: 1px solid #CCCCCC;display: inline-block;border-radius: 100%;margin-right: 5px;cursor: pointer; float:left; margin-top:10px;}
.Basic_information dd label.select-label i{border: 4px solid #6098f5;width: 6px;height: 6px}
.li_input{height:32px; line-height:32px; border:1px solid #E6E6E6; border-radius: 4px; padding:0 5px; color:#262626;font-family:"HiraginoSansGB-W3"; font-size:14px; }
.w80{ width:68px; }
.w150{ width:138px; }
.w490{ width:478px; }
.w270{ width:268px; }
.Basic_information dd p.tips{ font-size:12px; color:#999;font-family:"HiraginoSansGB-W3"; line-height:23px;}
.Basic_information .t_line{margin:0 0 28px;}


.Privacy_reminding{border:1px solid #F0F0F0; background:#fff;padding:5px 20px 20px; min-height:700px;border-radius:4px;}
.Privacy_reminding dl{border-bottom:1px solid #F0F0F0; padding:10px 0 20px; }
.Privacy_reminding dl:nth-last-of-type(1){ border-bottom:none}
.Privacy_reminding dl dt{line-height:34px; font-size:14px; color:#333;font-family:"HiraginoSansGB-W3"; margin-top:9px;}
.Privacy_reminding dl dd p{font-size:12px; color:#999;font-family:"HiraginoSansGB-W3"; line-height:20px;}
.Privacy_reminding label{ width:33%; display:inline-block; color:#262626; font-size:14px;}
.Privacy_reminding label.cf1{ cursor:pointer;  line-height:34px;  font-size:14px; font-weight:normal; margin-bottom:0; float:left}
.Privacy_reminding  label i{width: 12px;height: 12px;border: 1px solid #CCCCCC;display: inline-block;border-radius: 100%;margin-right: 5px;cursor: pointer; float:left; margin-top:11px;}
.Privacy_reminding label.select-label i{border: 4px solid #6098f5;width: 6px;height: 6px}

.Bind_Account{border:1px solid #F0F0F0; background:#fff;padding:5px 20px 20px; border-radius:4px;}
.Bind_Account dl{ margin-top:55px;}
.Bind_Account dl dt{ float:left; width:250px;}
.Bind_Account dl dt img{ display:block; width:50px; height:50px;border-radius:100%;  float:left; margin-right:20px;}
.Bind_Account dl dt span{ font-size:18px; color:#262626;font-family:"HiraginoSansGB-W6";font-weight:bold; float:left; line-height:50px;}
.Bind_Account dl dd{ float:left; width:166px; line-height:50px; color:#666;font-family:"HiraginoSansGB-W6";font-weight:bold; font-size:18px;}
.Bind_Account dl dd a{ font-family:"HiraginoSansGB-W3"; float:right; margin-right:20px; color:#fff; font-size:12px; height:38px; background:#6098f5; border:1px solid #6098f5; margin-top:5px; line-height:38px; width:98px; text-align:center; display:inline-block;border-radius:4px;}
.Bind_Account dl dd a.modify{ color:#fff; background:#6098f5;}
.Course{border:1px solid #F0F0F0; background:#fff;padding:5px 20px 20px; min-height:700px;border-radius:4px;}
.Course ul li{line-height:25px; padding:24px 0; border-bottom:1px solid #E6E6E6;}
.Course ul li a{ font-size:14px; color:#333;font-family:"HiraginoSansGB-W6";font-weight:bold; line-height:25px; display:block;}
.Course ul li:nth-last-of-type(1){ border-bottom:none}

/*个人中心页_日常奖励*/
.reward{border:1px solid #F0F0F0; background:#fff;padding:19px 20px 18px; border-radius:4px; margin-bottom:20px;}
.reward dl dt{ width:190px; float:left; line-height:33px; font-size:14px; color:#999;font-family:"HiraginoSansGB-W3"; text-align:right; margin-right:15px; }
.reward dl dt i{ font-size:12px;}
.reward dl dd{ float:left; line-height:33px; font-size:16px; color:#1A1A1A;font-family:"HiraginoSansGB-W6";font-weight:bold; width:377px;}
.reward dl dd i{font-family:"HiraginoSansGB-W3"; font-size:12px;}
.reward dl dd a{font-family:"HiraginoSansGB-W3"; float:right; color:#6098f5; background:#eff4fe;font-size:12px; margin-top:5px; height:24px;line-height:24px; width:64px; text-align:center; display:inline-block;border-radius:4px;}
.reward dl dd a:hover{ background:#80adf7; color:#fff;}
.reward dl dd a:visited{ background:#6098f5; color:#fff;}
.Reward_details{border:1px solid #F0F0F0; background:#fff;padding:19px 0 0; border-radius:4px; margin-bottom:20px;}

.Reward_details ul li{ width:33%; float:left; text-align:center; font-size:14px; color:#666; line-height:34px;font-family:"HiraginoSansGB-W3"; border-bottom:1px solid #EFEFEF; padding:19px 0}
.Reward_details ul li a{color:#6098f5; }
.Reward_details ul table{border:1px solid #EFEFEF; border-top:none; padding-bottom:7px;}
.Reward_details ul table tr th{ color: #666; font-weight:normal; font-size:14px;font-family:"HiraginoSansGB-W3"; border-bottom:1px solid #EFEFEF; text-align:center; line-height:20px; padding:11px 0;  }
.Reward_details ul table tr td{ color: #1A1A1A; font-size:14px;font-family:"HiraginoSansGB-W3"; text-align:center;line-height:20px; padding:6px 0 }
.Reward_details ul table tr td b{font-family:"HiraginoSansGB-W6";font-weight:bold; font-weight:normal;}
.Reward_details ul{ margin:0 20px;}
.Reward_details ul:first-of-type{ margin:0;}
.Reward_details ul:nth-last-of-type(1) li{ border-bottom:none}
.Reward_grant{border:1px solid #F0F0F0; background:#fff;padding:19px 20px 0; border-radius:4px; margin-bottom:20px;}
.Reward_grant ul li{ font-size:14px; color:#1A1A1A; line-height:34px;font-family:"HiraginoSansGB-W3"; border-bottom:1px solid #EFEFEF; padding:20px 0}
.Reward_grant ul li i{ margin-right:8px;}
.Reward_grant ul li b{font-family:"HiraginoSansGB-W6";font-weight:bold; font-weight:normal; margin:0 5px;}
.Reward_grant ul li i.rc{ color:#999999}
.Reward_grant ul li i.ew{ color:#6098f5}
.Reward_grant ul li i.tc{ color:#F66060}
.Reward_grant ul li i.yq{ color:#E6A23C}
.Reward_grant ul li i.zc{ color:#74E33D}
.Reward_grant ul li:nth-last-of-type(1) { border-bottom:none}


/*所有用户*/
.sc_topics span{ font-size:14px; color:#333;font-family:"HiraginoSansGB-W3"; float:left; line-height:24px; margin-right:17px; margin-top:10px;}
.sc_topics a{min-width:42px;height:22px; border:1px solid  #6098f5;border-radius:12px; display:inline-block; float:left; margin:10px 10px 0 0; font-size:12px; color:#6098f5;font-family:"HiraginoSansGB-W3"; text-align:center; line-height:22px;}
.sc_topics a:hover{ background:#eff4fe}
.Special_column dl dt i{ position:absolute; z-index:22;font-family:"HiraginoSansGB-W6";font-weight:bold; position:absolute; z-index:33; top:0; left:0; background:#6098f5;border-radius:4px; display:inline-block; width:18px; height:18px; text-align:center; line-height:18px; color:#fff; font-size:12px;}
.Special_column dl dt i.red{ background:#F66060}
.Special_column dl dt i.Yellowish{ background:#E6A23C}
.Special_column dl dt i.green{ background:#74E33D}

/*搜索结果页*/
.follow_BUT ul li{ width:49%; float:left; text-align:center; border-right:1px solid #EFEFEF}
.follow_BUT ul li:nth-last-of-type(1){border-right:none}
.follow_BUT  ul li span{ display:block; font-size:14px; color:#ccc;font-family:"HiraginoSansGB-W3"; line-height:20px;}
.follow_BUT  ul li  b{display:block; font-size:16px; color:#1A1A1A;font-family:"HiraginoSansGB-W6";font-weight:bold; line-height:25px; font-weight:normal}
.Search_jg{ padding-top:2px; }
.Search_jg dl{padding-bottom:22px;}
.Search_jg .foot_bq{ margin-top:15px;}

/*问题、文章详情页*/
.problem_box{background:#fff; padding:52px 0 30px}
.problem_bq{ float:left; width:210px; margin-top:7px; }
.problem_box  .problem_bq{text-align:right;}
.problem_bq a{min-width:32px;height:22px; padding:0 9px; border:1px solid  #6098f5;border-radius:12px; display:inline-block;  margin:0 10px 10px 0; font-size:12px; color:#6098f5;font-family:"HiraginoSansGB-W3"; text-align:center; line-height:22px;}
.problem_bq a em{ margin-left:5px;}
.problem_bq a:hover{ background:#eff4fe}
.problem_d{ width:584px; float: right; margin-left: 32px; padding:0 20px;}
.problem_d h2{ font-size:22px; color: #262626; line-height:32px;font-family:"HiraginoSansGB-W6";font-weight:bold; margin-bottom:13px;}
.problem_d span.money{ font-size:16px; color:#FC6C38;line-height:28px;font-family:"HiraginoSansGB-W6";font-weight:bold; display:inline-block;background:url("../img/uub.png") left center no-repeat; padding-left:20px}
.problem_d p{color:#1A1A1A; font-size:16px; line-height:24px;font-family:"HiraginoSansGB-W3"; margin-bottom:17px; overflow:hidden}
.problem_d p a{ color:#6098f5}
.problem_bq label.edit{background:url("../img/bj.png") left center no-repeat; background-size:14px;color:#999; display:inline-block; font-size:14px; margin-right:20px;font-family:"HiraginoSansGB-W3"; line-height:34px; cursor:pointer; padding-left:20px;}
.wz_details .Create_collector{ margin-top:0}
.wz_details .Create_collector input{ width:380px;}
.wz_details .Create_collector .ser_list{ width:390px;}
.wz_details  .ser_list ul li{ padding:0; border-bottom:none}
.foot_bq{ margin-top:-5px;}
.foot_bq label{color:#999; font-size:12px; margin-right:20px;font-family:"HiraginoSansGB-W3"; line-height:30px; cursor:pointer; padding-left:15px;}
.foot_bq label a{color:#999;}
.foot_bq label.zan{background:url("../img/zan.png") left center no-repeat; background-size:14px }
.foot_bq label.hzan{background:url("../img/h_zan.png") left center no-repeat; background-size:14px }
.foot_bq label.comment{background:url("../img/comment.png") left center no-repeat; background-size:14px }
.foot_bq label.fx{background:url("../img/fx.png") left center no-repeat; background-size:14px; position:relative; }
.foot_bq label.invitation{background:url("../img/yq_pre.png") left center no-repeat; background-size:14px }
.foot_bq label.report{background:url("../img/jb.png") left center no-repeat; background-size:14px }
.foot_bq label.follow{background:url("../img/szs.png") left center no-repeat; background-size:14px }
.foot_bq label.edit{background:url("../img/bj.png") left center no-repeat; background-size:14px }
.foot_bq label.qx_Collection{background:url("../img/del.png") left center no-repeat; background-size:14px }
.foot_bq label.browse{background:url("../img/browse.png") left center no-repeat; background-size:14px }
.foot_bq label.sc{background:url("../img/sc_ico1.png") left center no-repeat; background-size:14px }
.foot_bq label.del{background:url("../img/del.png") left center no-repeat; background-size:14px }

.foot_bq label.fr{ color:#ccc; margin-right:0; font-size:12px;}
.foot_bq label i{ color:#666;font-family:"HiraginoSansGB-W3"}
.foot_bq label.follow:hover{background:url("../img/szs2.png") left center no-repeat;background-size:14px; }
.foot_bq label.comment:hover{background:url("../img/comment2.png") left center no-repeat;background-size:14px; }
.foot_bq label.sc:hover{background:url("../img/sc_ico2.png") left center no-repeat; background-size:14px }

.foot_bq label.follow_hover{background:url("../img/szs3.png") left center no-repeat;background-size:14px; }
.foot_bq label.comment_hover{background:url("../img/comment3.png") left center no-repeat;background-size:14px; }
.foot_bq label.sc_hover{background:url("../img/sc_ico3.png") left center no-repeat; background-size:14px }

.fx_list{ width:125px; border:1px solid #f0f0f0; position:absolute; z-index:9; left:-41px; top:27px; background:#fff; padding-bottom:6px;}
.fx_list ul li{  line-height:39px; text-align:center}
.fx_list ul li a{color:#1A1A1A; font-size:14px; margin-right:20px;font-family:"HiraginoSansGB-W3"; line-height:39px; cursor:pointer; padding-left:35px;}
.fx_list ul li.qq a{background:url("../img/qq.png") 14px center no-repeat; background-size:16px }
.fx_list ul li.wb a{background:url("../img/wb.png") 14px center no-repeat; background-size:16px }
.fx_list ul li.wx a{background:url("../img/wx.png") 14px center no-repeat; background-size:16px }
.fx_list ul li:hover{ background: #FAFAFA}
.fx_list ul li img{ width:100px; height:100px; display:block; margin:0 auto;}


.problem_but{ width:274px; float:right; margin-left:10px; }
.problem_but a{font-family:"HiraginoSansGB-W3"; float:right; color:#6098f5; font-size:12px; height:32px; border:1px solid #6098f5; margin-left:10px; line-height:32px; width:118px; text-align:center; display:inline-block;border-radius:4px;}
.problem_but a.w60{ width:60px;}
.problem_but a:hover{ background:#6098f5; color:#fff;}
.problem_but a.gz_but{ background:#6098f5; color:#fff;}
.problem_but ul li{ position:relative;}
.Administration_hover{ position:absolute; z-index:33; background:#fff; top:43px; right:0; width:128px; border:1px solid #6098f5; border-radius:4px;}
.Administration_hover a{ border:none; display:block; height:39px; line-height:39px; padding:0 15px; text-align:left; float:none;width:98px; margin-left:0;border-radius:0;}
.Administration_hover a:hover{ background:#ecf2fd;color:#6098f5;}



.guide ul li{ line-height:20px; margin-bottom:15px;}
.guide ul li a{ color:#4D4D4D; font-size:14px; line-height:20px;font-family:"HiraginoSansGB-W3";}
.guide ul li a:visited{ color:#999}
.guide ul li a:hover{color:#6098f5;}
.person_concern p{ color:#808080; font-size:12px;font-family:"HiraginoSansGB-W3";}
.Initiator dl{ margin-top:21px; padding-bottom:10px;}
.Initiator dl dt{width:50px;height:50px; border-radius:4px; margin:0 10px 0 0;float:left;}
.Initiator dl dt img{width:50px;height:50px; border-radius:4px; margin:0 auto; display:block}
.Initiator dl dd{float:left; width:158px; text-align:left; }
.Initiator dl dd a{font-size:16px; color:#1A1A1A;font-family:"HiraginoSansGB-W6";font-weight:bold;line-height:25px; margin-top:4px; display:block; }
.Initiator .Invitation_but { margin-top:15px; margin-bottom:3px;}
.Initiator .Invitation_but button{ background:#fff; border:1px solid #6098f5; color:#6098f5; font-size:14px; height:34px;font-family:"HiraginoSansGB-W3" ;  cursor:pointer; line-height:33px; width:100%; text-align:center; display:inline-block;border-radius:4px;}
.Initiator .Invitation_but button:hover{ background:#ecf2fd}
.Initiator .Invitation_but button.active{ background:#6098F5; color:#fff;}
.Initiator .Invitation_but label{background:#eee; color:#aaa; font-size:14px; height:34px;font-family:"HiraginoSansGB-W3"; line-height:33px; width:100%; text-align:center; display:inline-block;border-radius:4px;}
.details_box{border:1px solid #F0F0F0; background:#fff;padding:0 20px 20px;min-height:700px; border-radius:4px;}
.details_box ul li{ border-bottom:1px solid #EFEFEF; padding:30px 0}
.details_box ul li:nth-last-of-type(1){border-bottom:none}
.details_top dl dt{width:40px; height:40px;border-radius:4px; float:left;margin-right:15px;}
.details_top dl dt img{ display:inline-block; width:40px; height:40px;border-radius:4px; vertical-align: middle; }
.details_top dl dd{ float:left; width:527px;}
.details_top dl dd a{ font-size:14px; color:#333333;font-family:"HiraginoSansGB-W6";font-weight:bold;font-weight:bold;}
.details_top dl dd label{color:#cccccc; font-size:14px;font-family:"HiraginoSansGB-W3"; float:right;}

.details_top dl dd p{ color:#808080; font-size:12px;  font-family:"HiraginoSansGB-W3"; line-height:20px;}
.details_box ul li .Agree_with{ margin-bottom:10px;}
.Agree_with span.money{font-size:12px; color:#FC6C38; margin-right:20px;line-height:28px;font-family:"HiraginoSansGB-W6";font-weight:bold; display:inline-block;background:url("../img/uub.png") left center no-repeat; padding-left:20px}
.hdbox{ margin-top:20px;}
.hdbox label{ color:#999; font-size:14px; margin-right:10px;font-family:"HiraginoSansGB-W3"; line-height:34px; display:inline-block; cursor:pointer}
.hdbox label.fr{ font-size:12px;color:#cccccc;font-family:"HiraginoSansGB-W3"; margin-right:0}
.hdbox label i{ color:#666;font-family:"HiraginoSansGB-W3"}
.hdbox label img{ margin-right:5px; width:14px;}
.details_box .Paran_text{  border-top:1px solid #EFEFEF; padding-top:30px;}
.details_box .tips{margin-bottom:30px; }

.tips p{ font-size:12px; color:#999;font-family:"HiraginoSansGB-W3"; line-height:23px;}
.wz_title{ margin:23px 0 16px; position:relative;}
.wz_title h2{  max-width:500px;color: #333;font-size: 18px;font-family:"Microsoft Yahei";line-height: 28px;}
.wz_details .problem_bq{ float:none; width:100%;}
.wz_title .problem_but{ position:absolute; right:0; top:0;}
.wz_title .problem_but ul li{ padding:0}
.Text_domain{border-top:1px solid #F0F0F0;margin:30px 0 20px; padding-top:30px;}
.Text_domain textarea{ border:1px solid #F0F0F0;border-radius:4px; height:80px; width:568px; padding: 5px; line-height:20px;color: #CCCCCC;font-size: 14px;font-family: "HiraginoSansGB-W3";}
.wz_fqr a{ line-height:25px;}
.wz_fqr p{line-height:25px; font-size:12px; color:#808080;font-family:"HiraginoSansGB-W3";}
.wz_details .problem_bq{ margin-bottom:5px;}
.wz_details .comment_box{ margin-top:30px;}
.wz_tt{ background:#fff; padding:23px 0; height:30px;position: fixed; z-index: 999; width: 100%;left: 0;top: 0;}
.wz_tt label{ color:#262626; font-size:22px; line-height:30px; display:inline-block; width:866px;font-family:"HiraginoSansGB-W6";}
.details_box ul li .comment_box{ margin-top:30px;}
.wz_details .No_more{ margin-top:30px;}
.Where{ padding:20px 0; background:#fff; border-bottom:1px solid #f0f0f0; border-top:1px solid #f0f0f0;}
.w_text{ display:block; width:584px;line-height:30px;margin:0 auto; margin-left:258px; overflow:hidden}
.w_text ul li{overflow: hidden;text-overflow:ellipsis;white-space: nowrap; height:30px; line-height:30px;}
.w_text span{ font-size:16px; color:#1A1A1A;font-family:"HiraginoSansGB-W3"; float:left}
.w_text label{font-family:"HiraginoSansGB-W6";font-weight:bold; color:#262626; width:460px; display:inline-block;overflow: hidden;text-overflow:ellipsis;white-space: nowrap;float:left; margin:0 5px;}

.p_fix{ position:relative;}

/*弹框-邀请的人*/
.Popup_bg{ background:rgba(0,0,0,.2);position: fixed;top: 0;left: 0;width: 100%;height: 100%;z-index: 7; }
.ceng{ position:relative; z-index:22; top:0; left:50%;}
.Popup_box{border:1px solid #f0f0f0; background:#fff;padding:5px 0; border-radius:4px; width:622px; margin:0 auto; position:relative; top:110px; z-index:50;}
.Popup_box em.close{ display:block; width:16px; height:16px; line-height:16px; text-align:center; position:absolute; z-index:999; right:-37px; top:0; cursor:pointer}
.left_tt{ width:325px; float:left; margin-right:10px; margin:0 10px 0 20px;}
.left_tt span{ display:inline-block; line-height:24px; float:left; color:#1A1A1A; font-size:16px;font-family:"HiraginoSansGB-W6";font-weight:bold; margin-top:5px;}
.left_tt a{ margin-left:10px; margin-top:6px;display:inline-block; width:24px; height:24px; line-height:24px;}
.left_tt a img{ display:inline-block; width:24px; height:24px; border-radius:4px;}
.invitee{ padding:21px 0}
.invitee .top_search{ margin-top:0; height:30px; line-height:30px;}
.invitee .top_search button{ margin-top:7px;}
.Create_collector{ margin-top:30px; position:relative}
.Create_collector .ser_list{ width:246px; top:33px;}
.Create_collector input{height:32px; line-height:32px; outline:none; border:1px solid #E6E6E6; vertical-align:0; border-radius:4px; width:236px; padding:0 5px;font-family:"HiraginoSansGB-W3"; color:#1A1A1A; font-size:14px;}
.Create_collector a.blue{font-family:"HiraginoSansGB-W3"; background:#6098f5;  color:#fff; font-size:14px; height:34px;  margin-left:10px; line-height:34px; width:100px; text-align:center; display:inline-block;border-radius:4px;}
.Create_collector a.gray{ color:#999999; font-size:14px; padding:0 25px;}

/*弹框-邀请回答*/
.answer ul{ border-top:1px solid #f0f0f0; margin-top:27px;}
.answer ul li{ border-bottom:1px solid #f0f0f0; height:40px; line-height:40px; padding:20px 0 21px; margin:0 18px;}
.answer ul li a.name{ font-size:16px; color:#333;font-family:"HiraginoSansGB-W6";font-weight:bold; margin-right:10px;}
.answer ul li a.name img{display:inline-block; width:40px; height:40px; border-radius:4px; float:left; margin-right:10px;}
.answer ul li a.bqian{ margin: 0 5px;font-family:"HiraginoSansGB-W3"; color:#6098f5; border:1px solid #6098f5;border-radius:12px; font-size:12px; height:22px; line-height:22px; padding:0 9px;  display:inline-block;}
.answer ul li span{ font-size:14px; color:#4D4D4D;font-family:"HiraginoSansGB-W3"; margin-right:10px;}
.answer ul li span i{ color:#1A1A1A; margin:0 3px;font-family:"HiraginoSansGB-W3";}
.answer ul li button{ cursor:pointer; float:right; margin-top:4px; background:#EFF4FE;font-family:"HiraginoSansGB-W3"; color:#6098f5; font-size:14px; height:34px; border:none;  line-height:34px; width:88px; text-align:center; display:inline-block;border-radius:4px;}
.answer ul li button:hover{ color:#fff; background:#80ADF7}
.answer ul li button:active{ color:#fff; background:#6098f5}
.answer ul li button.active{ color:#fff; background:#6098f5}

/*弹框-举报问题*/
.w400{width:438px;}
.Report { padding:20px 20px 34px;}
.Report h4{ text-align:center;font-size:16px; color:#1A1A1A;font-family:"HiraginoSansGB-W6";font-weight:bold; line-height:22px; margin-bottom:28px;}
.Report textarea{border:1px solid #E5E5E5;border-radius:4px; width:388px; margin-bottom:5px; height:146px;font-family:"HiraginoSansGB-W3"; color: #e4e4e4; padding:5px; max-width:388px; font-size:14px; }
textarea.black{ color:#1a1a1a}
.Report button{ cursor:pointer; margin:20px auto 0;  background:#6098f5;font-family:"HiraginoSansGB-W3"; color:#fff; font-size:14px; height:34px; border:none;  line-height:34px; width:216px;  text-align:center; display:block;border-radius:4px;}
.Report input{border:1px solid #E5E5E5; outline:none; height:32px; line-height:32px; padding:0 5px; color: #1a1a1a; font-size:14px;font-family:"HiraginoSansGB-W3"; width:388px; margin-bottom:20px;border-radius:4px;}
.Report p{ text-align:center; color:#F75E5E; font-size:14px; line-height:20px;font-family:"HiraginoSansGB-W3"; }
.Report p.blue{color:#6098f5}
.comment_box{ border:1px solid #F0F0F0;border-radius:4px; padding:2px 20px 20px;}
.comment_box ul li{ padding:20px 0 16px;border-bottom:1px solid #F0F0F0;}
.comment_box ul li:nth-last-of-type(1){ border-bottom:1px solid #F0F0F0;}
.pl_top a{font-size:14px; color:#333333;font-family:"HiraginoSansGB-W6";font-weight:bold; margin-right:10px;}
.pl_top a:hover{color:#6098f5}
.pl_top a img{display:inline-block; width:30px; height:30px; border-radius:4px; float:left; margin-right:10px;}
.pl_top label{ color:#cccccc; font-size:12px;font-family:"HiraginoSansGB-W3"}
.pl_top a.fr{color:#6098f5; margin-right:0; margin-left:10px;}
.text_bottom{ margin-top:7px; margin: 7px 0 0;}
.text_bottom p{ font-size:16px; color:#1A1A1A; line-height:25px;font-family:"HiraginoSansGB-W3"}
.plifo{ margin-top:20px;}
.plifo input{border:1px solid #E5E5E5; height:46px; line-height:46px; padding:0 5px; color: #666; font-size:14px;font-family:"HiraginoSansGB-W3"; width:528px; margin-bottom:20px;border-radius:4px;}
.plifo textarea{border:1px solid #E5E5E5; height:46px; line-height:46px; padding:0 5px; color: #666; font-size:14px;font-family:"HiraginoSansGB-W3"; width:528px;max-width:528px; margin-bottom:20px;border-radius:4px;}
.plifo textarea.black{ color:#1a1a1a}
.plifo p { text-align:right;}
.plifo p a.gray{ font-size:14px; color:#999;height:34px; line-height:34px; margin-right:15px;}
.plifo p a.pl{ cursor:pointer;  background:#EFF4FE;font-family:"HiraginoSansGB-W3"; color:#6098f5; font-size:14px; height:34px; border:none; margin-left:10px; line-height:34px; width:120px; text-align:center; display:inline-block;border-radius:4px;}
.plifo p a.pl:hover{ background:#6098f5; color:#fff}
.plifo p a.active{ background:#6098f5; color:#fff}
.comment_box ul li .hdbox{ margin-top:0;}
.tk_ser{ position:relative}
.tk_ser .ser_list{top:48px;}

/*弹框-推荐到帮助中心*/
.h_center{ padding-bottom:0}
.h_center .Report{ padding-bottom:0}
.h_center .Collection .answer{ margin-bottom:0}
.h_center .answer ul li:nth-last-of-type(1){ border-bottom:none}
.answer ul li button.yxz{ background:#6098f5; color:#fff}

/*头像悬停*/
.h_Hovering{ width:296px; position:absolute; z-index:99}
.Initiator dl dd span{ color:#808080; font-size:12px; line-height:20px;font-family:"HiraginoSansGB-W3"; display:block;}
.h_Hovering a{ display:block; line-height:30px;}
.h_Hovering .Invitation_but button{ float:left}
.h_Hovering .Invitation_but button.w120{ width:118px;background:#6098f5; color:#fff }
.h_Hovering .Invitation_but button.w88{ width:66px; margin-left:15px;}
.h_Hovering dl{ margin-top:6px; padding-bottom:16px;}



/*登录页*/

.dl_bg{ background:#f6f6f6}
.Sign_inbox{ border:1px solid #F0F0F0;border-radius:4px; padding:0 69px 20px; height:578px; width:860px; background:#fff; margin:6% auto 0; }
.lefft_js{ width:440px; float:left; position:relative;}
.zc_rule{
	height: 570px;padding:55px 40px;color:#fff; margin-top:-40px; border-radius:4px; width:360px;
    background: -webkit-linear-gradient(#81B0FF, #6098F5); /* Safari 5.1 - 6.0 */
    background: -o-linear-gradient(#81B0FF, #6098F5); /* Opera 11.1 - 12.0 */
    background: -moz-linear-gradient(#81B0FF, #6098F5); /* Firefox 3.6 - 15 */
    background: linear-gradient(#81B0FF, #6098F5); /* 标准的语法（必须放在最后） */}
.zc_rule h2{ font-size:22px; font-family:"HiraginoSansGB-W6";font-weight:bold;line-height:30px; margin-bottom:40px;}
.zc_rule dl dt{ font-size:18px; line-height:25px;font-family:"HiraginoSansGB-W6";font-weight:bold; margin-bottom:10px;}
.zc_rule dl dd{ font-size:14px; line-height:20px; margin-bottom:6px;font-family:"HiraginoSansGB-W3";}	
.zc_rule dl dd em{ font-size:14px; line-height:20px;float:left; display:inline-block; width:30px;font-family:"HiraginoSansGB-W3";}
.zc_rule dl dd span{ font-size:14px; line-height:20px;float:left; display:inline-block;width:330px;font-family:"HiraginoSansGB-W3";}
.lc_pic{ margin:37px 0 36px;}
.zc_rule dl dd p{font-size:12px; line-height:16px;font-family:"HiraginoSansGB-W3"; margin-top:30px;}
.Sign_info{ float:left; width:360px; margin-left:60px; }
.Sign_info h3{ font-size:22px; color:#1A1A1A;font-family:"HiraginoSansGB-W3"; line-height:30px;  margin:64px 0 3px;}
.Sign_info h5{font-size:16px; color:#B3B3B3;font-family:"HiraginoSansGB-W3"; line-height:30px; margin-bottom:10px;}
.Sign_info h5 i{ color:#6098F5; margin:0 5px;}
.Sign_info ul li{margin-top:15px;}
.Sign_info ul li input{ height:46px; line-height:46px; border:1px solid #E5E5E5;border-radius:4px; width:348px; padding: 0 5px; color: #1A1A1A; font-size:16px;}
.Sign_info ul li input::-webkit-input-placeholder{color:#B3B3B3;}
.Sign_info ul li input::-moz-placeholder{color:#B3B3B3;}
.Sign_info ul li input:-moz-placeholder{color:#B3B3B3;}
.Sign_info ul li input:-ms-input-placeholder{color:#B3B3B3;}


.Sign_info ul li input:focus{ border:1px solid #6098f5}
.Sign_info p{ margin-top:8px;}
.Sign_info p.red{color: #F75E5E;font-size: 14px;line-height: 20px;font-family:"HiraginoSansGB-W3"; }
.Sign_info p span{ font-size:14px; color:#666; line-height:25px;font-family:"HiraginoSansGB-W3"; cursor:pointer}
.Sign_info p span a{ color:#6098F5; margin-left:2px;}
.Sign_info p span em{ display:inline-block; width:12px; height:12px; border:1px solid #6098f5;border-radius:12px; vertical-align:-2px; margin-right:5px;}
.Sign_info p span.cur em{background:url("../img/gou2.png") left center no-repeat; width:14px; height:14px; border-radisu:14px; border:0;}
.Sign_info p span.fr{ float:right;}
.Sign_info p span.fr a{font-size:14px; color:#666; line-height:25px;font-family:"HiraginoSansGB-W3";}
.Sign_info p span.fr a.fr{color:#6098F5;}
.login_but{  margin:9px 0 55px}
.login_but button{ font-size:16px; width:168px; border:1px solid #6098f5;border-radius:4px;font-family:"HiraginoSansGB-W3"; height:48px; line-height:48px; text-align:center; cursor:pointer}
.login_but button.dl{ background:#6098f5; color:#fff; margin-right:20px;}
.login_but button.zc{background:#fff; color:#6098f5;}
.login_but button.w100{ width:100%}
.Other_way{margin-bottom:45px;}
.Other_way h4{font-size:16px; color:#333;font-family:"HiraginoSansGB-W3"; line-height:30px; margin-bottom:6px;}
.Other_way a{ display:inline-block; width:34px; height:34px; line-height:34px; text-align:center; margin-right:15px; float:left;}
.Other_way a img{ display:inline-block; width:34px; height:34px; line-height:34px; text-align:center;}
.f_t a{font-size:14px; color:#6098f5;font-family:"HiraginoSansGB-W3"; line-height:30px;}
.f_t i{ display:inline-block; width:3px; height:3px; line-height:3px; vertical-align:3px; background:#6098f5; text-align:center;border-radius:3px; margin:0 2px;}

.mrdl{width:440px; height:680px; margin-top:-40px; border-radius:4px; }
.mrdl img{ width:440px; height:680px;}
.zc_info h3{ margin:44px 0 1px;}
.zc_info h5{ margin-bottom:10px;}
.Sign_info ul li input.w220{ width:208px;}
.Sign_info ul li button{height:48px; cursor:pointer; line-height:48px; border:1px solid #6098F5; background:#fff;border-radius:4px; width:120px; padding: 0 5px; color:#6098F5; font-size:16px; margin-left:20px; vertical-align:-2px;}

.zc_info .login_but{ margin-bottom:10px;}
.Retrieve_password h3{ margin-top:114px; margin-bottom:0}
.Retrieve_password h5{ margin-bottom:22px;}
.Retrieve_password .login_but{ margin-top:43px;}


/*收藏弹框*/
.Collection .answer{ margin-bottom:20px;}
.Report button.w100{ width:100px;}
.Report .bor_but{ border:1px solid #999; background:none; color:#4D4D4D; margin-left:20px;}
.foot_but{ text-align:center; position:relative;}
.foot_but button{ display:inline-block;}
.foot_but button.b_ule{ color:#6098F5; background:#EFF4FE;margin-left:20px; background-size:19px 31px}

.Report input:nth-last-of-type(1){ margin-top:15px;}
.foot_but input.radio{ width:15px;color: #000; text-align: left; margin:0 5px 0 0; cursor:pointer}
.foot_but span{ margin-left:20px;color:#666; font-size:14px; font-family:"HiraginoSansGB-W3";cursor:pointer; position:absolute; right:0; top:22px;}



/*用户协议*/
.User_protocol { padding:30px 20px 34px; height:500px; overflow:auto}
.w540{ width:540px;}
.User_protocol h4{ text-align:center;font-size:16px; color:#1A1A1A;font-family:"HiraginoSansGB-W6";font-weight:bold; line-height:22px; margin-bottom:20px;}
.User_protocol p{ color:#1A1A1A; font-size:14px; line-height:24px;font-family:"HiraginoSansGB-W3";}
.User_protocol h5{ color:#1A1A1A; font-size:14px; line-height:24px;font-family:"HiraginoSansGB-W6";font-weight:bold; margin-top:30px;}

/*点赞成功*/
.Praise_success { position:fixed; z-index:999; top:68px; left:0; width:100%}
.Praise_success a{ height:60px; line-height:60px; background:#6fa1f5; color:#fff; font-size:16px;font-family:"HiraginoSansGB-W3"; display:block; text-align:center}
.Back_top{ height:50px; width:50px;border-radius:2px; position:fixed; right:50px; bottom:195px;}
.Back_top a{ display:block;height:50px; width:50px;border-radius:2px; background:#EFF4FE url("../img/z_b.png") center center no-repeat; background-size:12px;}
.Back_top a:hover{ background:#6098F5 url("../img/z_w.png") center center no-repeat; background-size:12px; }

/*问题重定向*/
.redirect-question .Popup_box .Report .aw-dropdown ul.aw-dropdown-list li{ height:20px;line-height: 20px;padding: 5px 0;border-bottom: 1px solid #f0f0f0;}
.redirect-question .Popup_box .Report .aw-dropdown ul.aw-dropdown-list li a{ width: 330px; display: inline-block;  float: left; font-size: 14px;color: #1a1a1a;font-family:"HiraginoSansGB-W3";overflow: hidden;text-overflow:ellipsis;white-space: nowrap;}
.redirect-question .Popup_box .Report .aw-dropdown ul.aw-dropdown-list li em.fr{font-size: 12px;color: #999;font-family:"HiraginoSansGB-W3";}

.details_box .fx_list ul li{ padding:0}


/*暂无结果404*/
.cw404{ min-height:530px; padding-top:200px;}
.cw404 img{ display:block; margin:0 auto}
.no_result img{display:block; margin:80px auto 15px;}
.no_result p{ text-align:center; font-size:16px; color:#CDCDCD; line-height:25px;}
.exit_text{ margin-top:35px;}
.exit_text p{ text-align:center; font-size:16px; color:#1A1A1A;font-family:"HiraginoSansGB-W3"; line-height:25px;}
.exit_text a{ color:#6098F5; line-height:25px; font-size:14px;font-family:"HiraginoSansGB-W3"; text-align:center; display:block;}

/*话题广场*/
.topic_square ul li{float:left; margin:0 12px 12px 0;padding:10px; border:1px solid #F0F0F0; border-radius:3px; height:72px; width:178px; background:#fff; overflow:hidden}
.topic_square ul li:nth-of-type(3n){ margin-right:0}
.topic_square ul li dl{ } 
.topic_square ul li dl dt{ float:left; width:40px; height:40px; margin-right:10px;}
.topic_square ul li dl dt img{ display:block; width:40px; height:40px; margin:0 auto; border-radius:100%;}
.topic_square ul li dl dd{ float:left; width:128px;}
.topic_square ul li dl dd a{ display:block; font-size:16px; margin-top:4px; color:#333;font-family:"HiraginoSansGB-W6";font-weight:bold; line-height:25px;overflow:hidden;text-overflow:ellipsis;white-space: nowrap; }
.topic_square ul li dl dd a:hover{ color:#6098F5}
.topic_square ul li .foot_bq{ margin-top:9px;}
.topic_square ul li .foot_bq label{ font-size:11px; color:#808080; line-height:22px; padding-left:17px; width:41px; margin-right:4px; display:inline-block; float:left } 
.topic_square ul li .foot_bq label.discuss{background:url("../img/discuss.png") left center no-repeat; background-size:12px }
.topic_square ul li .foot_bq label.follow2{background:url("../img/follow2.png") left center no-repeat; background-size:12px }
.topic_square ul li .foot_bq a{ display:inline-block; height:20px; line-height:20px; width:29px; padding-bottom:0; margin-top:0; border:1px solid #f0f0f0; font-size:12px;color:#808080; background:url("../img/add.png") 4px center no-repeat; background-size:10px; padding-left:19px;font-family: HiraginoSansGB-W3;  }
.topic_square ul li .foot_bq a.gray{ background:none; padding:0; text-align:center; width:48px;}
.topic_label a{ height:28px; line-height:28px; margin:10px 6px 0 0; border:1px solid #6098f5;border-radius:15px; padding:0 15px; display:inline-block; color:#6098f5; font-size:14px;font-family:"HiraginoSansGB-W3"; }
.topic_label a em{ margin-left:5px; cursor:pointer}
.problem_box .topic_label{ margin-top:0}


/*话题新改*/
.Topic_new{border:1px solid #F0F0F0; background:#fff;padding:5px 20px 0; min-height:700px;border-radius:4px;}
.Topic_new dl{ border-bottom:1px solid #EFEFEF; padding:18px 0 20px; width:281px; float:left; padding-right:20px;}
.Topic_new dl:nth-of-type(2n){  padding-right:0;}
.Topic_new dl:nth-last-of-type(1){ border-bottom:none}
.Topic_new dl:nth-last-of-type(2){ border-bottom:none}
.Topic_new dl dt{ height:60px; line-height:60px; margin-right:13px; float:left; }
.Topic_new dl dt img{ display:inline-block; width:60px; height:60px; vertical-align: middle; }
.Topic_new dl dd{ float:left; width:208px; }
.Topic_new dl dd a{ font-size:18px; color:#333333;font-family:"HiraginoSansGB-W6";font-weight:bold;line-height:35px; }
.Topic_new dl dd p{ font-size:14px; color:#4D4D4D; line-height:20px;font-family:"HiraginoSansGB-W3";}
.Topic_new dl dd p i{ color:#1A1A1A; margin:0 5px; font-style:normal}
.Topic_new dl dd button{cursor:pointer; margin-top:14px; background:#EFF4FE;font-family:"HiraginoSansGB-W3"; color:#6098f5; font-size:14px; height:34px; border:none; margin-left:0; line-height:34px; width:80px; text-align:center; display:inline-block;border-radius:4px;}
.Topic_new dl dd button:hover{ color:#fff; background:#80ADF7}
.Topic_new dl dd button.active{ color:#fff; background:#6098f5}
pre {
white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;

}

/*热门作者*/
.Popular_author{border:1px solid #F0F0F0; background:#fff;padding:9px; min-height:700px;border-radius:4px;}
.Popular_author dl{ float:left; width:277px; height:80px; padding:10px; margin-right:10px; margin:0 10px 10px 0; cursor:pointer}
.Popular_author dl:nth-of-type(2n){  margin-right:0;}
.Popular_author dl dt { width:80px; height:80px;line-height:80px;border-radius:100%; margin-right:10px; float:left}
.Popular_author dl dt a{ display:inline-block; width:80px; height:80px; line-height:80px;border-radius:100%;}
.Popular_author dl dt img{ display:inline-block; width:80px; height:80px;border-radius:100%;}
.Popular_author dl dd{padding-top:10px;float:left; width:187px; }
.Popular_author dl dd a{ font-size:18px; color:#333333;font-family:"PingFangSC-Regular";line-height:20px; display:block;overflow:hidden;text-overflow:ellipsis;white-space: nowrap; }
.Popular_author dl dd h5{font-size:12px; color:#666; line-height:16px;font-family:"PingFangSC-Regular"; max-height:32px; padding:8px 0 5px}
.Popular_author dl dd p i{ color:#6095F2 }
.Popular_author dl dd p { color:#3F3F3F;font-size:12px; color:#666; line-height:14px;font-family:"PingFangSC-Regular";}
.Popular_author dl dd p em{ display:inline-block; width:4px; height:4px; background:#C9CED6; border-radius:100%; margin:0 10px; vertical-align:2px;}
.Popular_author dl:hover{background:#f6f8fd; }
.tj_articles{border:1px solid #EFEFEF; background:#fff; padding:22px 15px 14px; margin-bottom:10px;border-radius:4px;}
.tj_articles h4{ font-size:16px; color:#1A1A1A; height:30px;line-height:30px;font-family:"PingFangSC-Regular";font-weight:bold; margin-bottom:5px;}
.tj_articles ul li{ border-bottom:1px solid #EFEFEF;  padding:10px 0; }
.tj_articles ul li a{ display:block; height:36px;color:#666; font-size:13px; line-height:18px; font-family:"PingFangSC-Regular";overflow:hidden;background:url("../img/sjx_ico.png") left 8px no-repeat; background-size:4px 6px; padding-left:10px;}
.tj_articles ul li a:visited { color:#999}
.tj_articles ul li a:hover{color:#6098f5}
.tj_articles ul li:nth-last-of-type(1){ border-bottom:none}
.tj_author dl dt{ border-radius:100%; overflow:hidden}

/*文章列表*/
.left_top{ float:left; width:260px;}
.right_top{ float:right; width:322px; margin-top:5px;}
.left_top span{ float:left;}
.left_top span a img{ display:inline-block; width:34px; height:34px; border-radius:100%; margin-right:10px;}
.left_top label{ float:left;}
.left_top label a{ display:block;font-family:"PingFangSC-Regular";font-size: 14px;color: #666666;line-height: 18px;}
.left_top label b{ display:block;font-family:"PingFangSC-Regular";font-size: 12px;color: #999;line-height: 16px; font-weight:normal}
.right_top span{ cursor:pointer;min-width:32px;height:22px; float:right; padding:0 9px; border:1px solid  #CCCCCC;border-radius:100px; display:inline-block;  margin:0 0 10px 10px ; font-size:12px; color:#CCCCCC;font-family:"HiraginoSansGB-W3"; text-align:center; line-height:22px;}
.Column_tp ul li a.wz_tit{ font-size:18px; font-weight:normal; margin-top:5px; display:block;color: #333;font-family: "Microsoft Yahei";line-height: 28px;}
.Column_tp ul li dl dt label{ font-size:12px; color:#999}
.Column_tp ul li dl dt label i{color:#999; margin-left:5px;}
.Column_tp ul li dl dt p{ line-height:25px; font-size:15px; color:#666; height:72px; margin-bottom:14px;}
.Column_tp ul li dl{ padding-bottom:14px;}
.wz_top{ padding-top:10px;}
.Column_tp ul li .title{ color: #333; font-size: 18px; font-family: "Microsoft Yahei"; line-height: 28px;}


pre {
	display: block; 
    overflow: auto; 
    padding: 0px 0px 20px 0px; 
    whitewhite-space:pre-wrap; /* css-3 */    
    whitewhite-space:-moz-pre-wrap; /* Mozilla, since 1999 */    
    whitewhite-space:-pre-wrap; /* Opera 4-6 */    
    whitewhite-space:-o-pre-wrap; /* Opera 7 */    
    word-wrap:break-word; /* Internet Explorer 5.5+ */  
    white-space: pre-wrap; /* Firefox */
}

.tk_box{border:1px solid #f0f0f0; background:#fff;padding:5px 0; border-radius:4px; width:480px; margin:0 auto; position:relative; top:110px; z-index:50;}
.tk_box h4{ font-size:18px; text-align:center; color:#333; line-height:30px; margin:40px 0 32px}
.tk_box em.close{ display:block; width:16px; height:16px; line-height:16px; text-align:center; position:absolute; z-index:999; right:20px; top:20px; cursor:pointer}
.binding ul li{ border:1px solid #E6EAF1; background:#F1F2F4; height:48px; width:368px; margin:0 auto 20px; padding-left:10px; border-radius:4px;}
.binding ul p{ border:1px solid #E6EAF1; background:#F1F2F4; height:48px; width:368px; margin:0 auto 20px; padding-left:10px; border-radius:4px;}
.binding ul img{ margin:0 80px 80px 80px;width:320px;height:320px;}
.binding ul li input{ height:48px; border:none; vertical-align:top; border-radius:4px; width:285px; font-size:16px; padding:0 10px }
.binding ul li input::-webkit-input-placeholder{color:#ACB0B7;}
.binding ul li input::-moz-placeholder{color:#ACB0B7;}
.binding ul li input:-moz-placeholder{color:#ACB0B7;}
.binding ul li input:-ms-input-placeholder{color:#ACB0B7;}

.binding ul li span{ height:48px; line-height:48px; font-size:16px; color:#666}

.binding ul li span em{display:inline-block; width:0; height:0; border-width:7px 6px 0; margin:0 10px 0 10px; border-style:solid; border-color:#BDBDBD  transparent transparent;/*灰 透明 透明 */}
.binding ul li.wsmall{ border:none; padding:0; background:none; width:380px;}
.binding ul li.wsmall input{ border:1px solid #E6EAF1; background:#fff; width:218px}
.binding ul li button{ background: #6095F2; cursor:pointer;width:120px; height:50px; line-height:50px; border:none; text-align:center; color:#fff; font-size:16px; border-radius:4px; margin-left:20px;}

.f_but a{ display:block; font-size:16px; color:#fff; height:50px; line-height:50px; width:380px; margin:40px auto 60px; text-align:center;border-radius:4px; background:#6095F2}






